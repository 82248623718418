import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { api } from "../../shared-api/router"

const useTeigpolygoner = (matrikkelnummer?: Matrikkelnummer) => {
  const queryKey = ["teigpolygoner", matrikkelnummer]
  const teigpolygonQuery = useQuery({
    queryKey,
    staleTime: konverterFraMinutterTilMillisekunder(30),
    queryFn: () => api.kart.hentTeigpolygonerForEiendom(matrikkelnummer!),
    throwOnError: true,
    enabled: !!matrikkelnummer,
  })

  return teigpolygonQuery
}

export default useTeigpolygoner
