import { Container, SkipNavContent } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import { TilpassetFooter } from "./features/core/components/footer/TilpassetFooter"
import Section from "./features/core/components/layout/Section"
import { Driftsmelding } from "./features/core/components/navbar/Driftsmelding"
import { NavBar } from "./features/core/components/navbar/NavBar"
import { NavBreadcrumb } from "./features/core/components/navbar/NavBreadcrumb"

const Root = () => {
  const location = useLocation()
  const posthog = usePostHog()
  const visBreadcrumb = location.pathname !== "/"
  const { t } = useTranslation([
    "hjelpOgStøtte",
    "omTjenesten",
    "personvernOgSikkerhet",
    "landingsside",
  ])

  const getSidetittel = useCallback(
    (path: string) => {
      switch (path) {
        case "/hjelp":
          return t("tab_tittel", { ns: "hjelpOgStøtte" })
        case "/om-tenesta":
          return t("tab_tittel", { ns: "omTjenesten", lng: "nn-NO" })
        case "/personvern":
          return t("tab_tittel", { ns: "personvernOgSikkerhet" })
        default:
          return t("tab_tittel", { ns: "landingsside" })
      }
    },
    [t],
  )

  const getBakgrunnsfarge = () => {
    switch (location.pathname.split("/")[1]) {
      case "eiendom":
        return "white"
      case "hjelp":
        return "white"
      default:
        return "gray.50"
    }
  }

  useEffect(() => {
    document.title = getSidetittel(location.pathname) as string

    if (window.location.hostname != "localhost") {
      posthog.capture("$pageview")
    }
  }, [location, posthog, getSidetittel])

  return (
    <>
      <NavBar />
      <Driftsmelding />
      <SkipNavContent id="main" />
      <Container bg={getBakgrunnsfarge()} p={0} minH="calc(100vh - 90px - 98px)" minW="100%">
        {visBreadcrumb && (
          <Section pt={{ base: "8px", md: "28px", lg: "28px", xl: "28px" }}>
            <NavBreadcrumb
              mb={{
                base: "24px",
                lg: "28px",
                xl: "28px",
              }}
            />
          </Section>
        )}
        <Outlet />
        <div aria-label={t("artikkel_slutt", { ns: "nøkkelbegreper" })} />
      </Container>
      <TilpassetFooter />
      <ScrollRestoration
        getKey={location => {
          const exludePaths = ["/hjelp"]
          return !exludePaths.includes(location.pathname) ? location.pathname : location.key
        }}
      />
    </>
  )
}

export default Root
