import { useTranslation } from "react-i18next"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { getMatrikkelenhetstype } from "../eiendomsoversikt/utils/eiendomUtils"
import DetaljPopover from "./DetaljPopover"

const MatrikkeltypePopover = ({ matrikkelenhetstype }: { matrikkelenhetstype: string }) => {
  const { t } = useTranslation(["nøkkelbegreper"])
  const { isDesktop } = useScreenView()
  const matrikkelenhetstypeAriaLabel = getMatrikkelenhetstype(matrikkelenhetstype)

  return (
    <DetaljPopover
      forklaring={t("matrikkelenhetstype_forklaring_begrep")}
      buttonAriaLabel={t("matrikkelenhetstype_forklaring_begrep_arialabel")}
      posthogName="matrikkelenhetstype_info_click"
    >
      <Brødtekst litenVariant color="gray.500" lineHeight="20px">
        {t("type_eiendom")}
      </Brødtekst>
      <Brødtekst
        color="gray.500"
        aria-labelledby={matrikkelenhetstypeAriaLabel}
        lineHeight="24px"
        fontSize={isDesktop ? "m" : "sm"}
        fontWeight="bold"
      >
        {getMatrikkelenhetstype(matrikkelenhetstype)}
      </Brødtekst>
    </DetaljPopover>
  )
}

export default MatrikkeltypePopover
