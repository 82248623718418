import { Box, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent } from "@kvib/react"

const PopoverInfo = ({ children }: { children?: React.ReactNode }) => (
  <PopoverContent
    borderColor="blue.100"
    borderWidth="1px"
    maxWidth="100vw"
    whiteSpace="normal"
    wordBreak="break-word"
    fontWeight="normal"
    p="14px"
  >
    <PopoverArrow
      sx={{
        "--popper-arrow-shadow-color": "var(--kvib-colors-blue-100)",
      }}
    />
    <PopoverCloseButton aria-label="Lukk" p="14px" />
    <Box mt="14px">
      <PopoverBody p={0}>{children}</PopoverBody>
    </Box>
  </PopoverContent>
)

export default PopoverInfo
