import { Divider } from "@kvib/react"
import { useTranslation } from "react-i18next"
import Section from "../../../core/components/layout/Section"
import { Overskrift } from "../../../core/text/components/Overskrift"
import EiendomskortListe from "../components/EiendomskortListe"

const MineEiendommer = () => {
  const paddingTopBottom = { base: "24px", lg: "80px", xl: "80px" }

  const { t } = useTranslation("nøkkelbegreper")

  return (
    <Section pt={paddingTopBottom} pb={paddingTopBottom}>
      <MineEiendommerOverskrift title={t("mine_eiendommer")} />
      <EiendomskortListe />
    </Section>
  )
}

interface MineEiendommerOverskriftProps {
  title: string
  ariaLabel?: string
}

const MineEiendommerOverskrift = ({
  title,
  ariaLabel,
  ...props
}: MineEiendommerOverskriftProps) => (
  <>
    <Overskrift aria-label={ariaLabel} {...props} variant="h1">
      {title}
    </Overskrift>
    <Divider
      borderColor="gray.200"
      borderWidth="2px"
      mt={{
        base: "10px",
        md: "12px",
      }}
      mb={{
        base: "12px",
        md: "16px",
      }}
    />
  </>
)

export default MineEiendommer
