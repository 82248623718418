import { apiRoute } from "../../../environments"
import { ApiError } from "../../core/error-handling/ApiError"
import { ErrorResponse, Kodelister } from "../../shared-api/types/ApiResponseTypes"

export const kodelisterRouter = {
  hentKodelister: async () => {
    const res = await fetch(`${apiRoute}/kodelister`)
    if (!res.ok) {
      const body: ErrorResponse = await res.json()
      throw new ApiError(body)
    }

    const data: Kodelister = await res.json()
    return data
  },
}
