import { errormeldinger } from "./errorMeldinger"
import { footer } from "./footer"
import { navbar } from "./navbar"
import { nøkkelbegreper } from "./nøkkelbegreper"
import { bruksenhetskort } from "./pages/bruksenhetskort"
import { bygningskort } from "./pages/bygningskort"
import { datafelter } from "./pages/datafelter"
import { detaljertInnsyn } from "./pages/detaljertInnsyn"
import { egenskaperVedBygning } from "./pages/egenskaperVedBygning"
import { eiendomskort } from "./pages/eiendomskort"
import { hjelpOgStøtte } from "./pages/hjelpOgStøtte"
import { landingsside } from "./pages/landingsside"
import { ofteStilteSpørsmål } from "./pages/ofteStilteSpørsmål"
import { omTjenesten } from "./pages/omTjenesten"
import { personvernOgSikkerhet } from "./pages/personvernOgSikkerhet"

type Locale = "nb-NO" | "nn-NO"

const bokmål = {
  landingsside: landingsside["nb-NO"],
  hjelpOgStøtte: hjelpOgStøtte["nb-NO"],
  ofteStilteSpørsmål: ofteStilteSpørsmål["nb-NO"],
  omTjenesten: omTjenesten["nb-NO"],
  footer: footer["nb-NO"],
  navbar: navbar["nb-NO"],
  nøkkelbegreper: nøkkelbegreper["nb-NO"],
  bygningskort: bygningskort["nb-NO"],
  eiendomskort: eiendomskort["nb-NO"],
  bruksenhetskort: bruksenhetskort["nb-NO"],
  detaljertInnsyn: detaljertInnsyn["nb-NO"],
  personvernOgSikkerhet: personvernOgSikkerhet["nb-NO"],
  errormeldinger: errormeldinger["nb-NO"],
  egenskaperVedBygning: egenskaperVedBygning["nb-NO"],
  datafelter: datafelter["nb-NO"],
} as const
const nynorsk: typeof bokmål = {
  landingsside: landingsside["nn-NO"],
  hjelpOgStøtte: hjelpOgStøtte["nn-NO"],
  ofteStilteSpørsmål: ofteStilteSpørsmål["nn-NO"],
  omTjenesten: omTjenesten["nn-NO"],
  footer: footer["nn-NO"],
  navbar: navbar["nn-NO"],
  nøkkelbegreper: nøkkelbegreper["nn-NO"],
  bygningskort: bygningskort["nn-NO"],
  eiendomskort: eiendomskort["nn-NO"],
  bruksenhetskort: bruksenhetskort["nn-NO"],
  detaljertInnsyn: detaljertInnsyn["nn-NO"],
  personvernOgSikkerhet: personvernOgSikkerhet["nn-NO"],
  errormeldinger: errormeldinger["nn-NO"],
  egenskaperVedBygning: egenskaperVedBygning["nn-NO"],
  datafelter: datafelter["nn-NO"],
} as const

export const resources: Record<Locale, typeof bokmål> = {
  "nb-NO": bokmål,
  "nn-NO": nynorsk,
} as const
