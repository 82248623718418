import { ErrorResponse } from "../../shared-api/types/ApiResponseTypes"
import { ErrorType } from "./types/ErrorType"

export class ApiError extends Error {
  type: ErrorType
  userErrorMessage: string
  constructor({ type, systemErrorMessage, userErrorMessage }: ErrorResponse) {
    super(systemErrorMessage)
    this.type = type
    this.userErrorMessage = userErrorMessage
  }
}
