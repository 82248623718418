import i18n from "i18next"
import { BygningskoordinaterResponse } from "../../../../shared-api/types/ApiResponseTypes"
import { Vegadresse } from "../../../eiendomsoversikt/types/Eiendom"
import { Bruksenhet, DetaljertEiendom } from "../../types/DetaljertEiendom"
import { Bygning } from "../types/Bygning"

export const sorterPåBygningstype = (bygninger: Bygning[]) =>
  bygninger.sort((a, b) => a.bygningstype.localeCompare(b.bygningstype))

export const bygningAria = (bygninger: Bygning[]) =>
  i18n.t("antall_bygninger_på_eiendommen", { ns: "bygningskort", count: bygninger.length })

export const getBruksenheterForBygning = (eiendom: DetaljertEiendom, bygning: Bygning) => {
  return eiendom.bruksenheterPåEiendom.filter(bruksenhet => bruksenhet.byggId === bygning.byggId)
}

export const getBruksenhetIds = (bruksenheter: Bruksenhet[]) => {
  return new Set(bruksenheter.map(b => b.bruksenhetsId))
}

export const getVegadresserPåBygning = (
  vegadresser: Vegadresse[] | undefined,
  bruksenhetIds: Set<string>,
) => {
  return vegadresser?.filter(
    (vegadresse: Vegadresse) =>
      vegadresse.bruksenhetsId && bruksenhetIds.has(vegadresse.bruksenhetsId),
  )
}

export const getBygningskoordinat = (
  bygningskoordinater: BygningskoordinaterResponse | null | undefined,
  bygning: Bygning,
) => {
  return bygningskoordinater?.find(koordinat => koordinat.byggId === bygning.byggId)
}

export function formaterBruksareal(bruksareal: string): string {
  const bruksarealFloat = parseFloat(bruksareal)
  return bruksarealFloat === 0.0
    ? i18n.t("ikke_registrert", { ns: "nøkkelbegreper" })
    : i18n.t("areal", {
        ns: "nøkkelbegreper",
        val: bruksarealFloat,
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })
}
