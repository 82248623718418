const keys = {
  "nb-NO": {
    ukjent_feil: "Ukjent feil",
    ukjent_feil_melding:
      "Det har oppstått en ukjent feil. Vennligst prøv igjen senere eller kontakt oss hvis feilen vedvarer",
    logg_inn_på_nytt: "Logg inn på nytt",
    tilbake_til_start: "Gå tilbake til startsiden",
    fant_ikke_side: "Fant ikke side",
    klarte_ikke_å_logge_ut_bruker: "Klarte ikke å logge deg ut",
    kunne_ikke_autentisere_bruker: "Kunnne ikke autentisere bruker",
    feil_i_autentiseringsrespons: "Feil i respons fra autentisering: {{status}}",
    kan_ikke_logge_inn_tittel: "Kan ikke logge inn",
    kan_ikke_logge_inn_beskrivelse:
      "Innlogging er ikke tilgjengelig for øyeblikket. Vi beklager ulempen. Du kan prøve igjen om et par minutter",
    fant_ikke_innhold_tittel: "Fant ikke innhold",
    fant_ikke_innhold_beskrivelse:
      "Hmm, her var det visst ikke noe. Sikker på at URL-en er riktig?",
    utløpt_session: "Din innloggede sesjon er utløpt",
    intern_systemfeil: "Intern systemfeil",
    nedetid_tittel: "Nedetid i kveld",
    nedetid_beskrivelse:
      "Fra klokken 16:00 til 23:59 vil det bli gjort vedlikehold på Kartverkets tjenester. Min eiendom vil i perioden være ustabil eller ikke fungere. Vi beklager ulempene.",
  },
  "nn-NO": {
    ukjent_feil: "",
    ukjent_feil_melding: "",
    logg_inn_på_nytt: "",
    tilbake_til_start: "",
    fant_ikke_side: "",
    klarte_ikke_å_logge_ut_bruker: "",
    kunne_ikke_autentisere_bruker: "",
    feil_i_autentiseringsrespons: "",
    kan_ikke_logge_inn_tittel: "",
    kan_ikke_logge_inn_beskrivelse: "",
    fant_ikke_innhold_tittel: "",
    fant_ikke_innhold_beskrivelse: "",
    utløpt_session: "",
    intern_systemfeil: "",
    nedetid_tittel: "",
    nedetid_beskrivelse: "",
  },
}

type ErrormeldingerKey = keyof (typeof keys)["nb-NO"]

type Errormeldinger = {
  [K in keyof typeof keys]: Record<ErrormeldingerKey, string>
}

export const errormeldinger: Errormeldinger = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
