import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const EiendomsarealDetalj = ({
  teigareal,
  erEierseksjon,
}: {
  teigareal: number
  erEierseksjon: boolean
}) => {
  const { t } = useTranslation(["detaljertInnsyn", "nøkkelbegreper"])

  return erEierseksjon ? (
    <DetaljPopover
      forklaring={t("eksklusivt_uteareal_forklaring") as string}
      buttonAriaLabel={t("ekslusivt_uteareal_forklaring_arialabel") as string}
    >
      <OverskriftForEiendomsdetaljer>
        {t("eksklusivt_uteareal_overskrift")}
      </OverskriftForEiendomsdetaljer>
      {teigareal !== -1 ? (
        <FeltverdiForEiendomsdetaljer
          fieldValue={
            teigareal
              ? t("areal", { ns: "nøkkelbegreper", val: teigareal })
              : t("ikke_registrert", { ns: "nøkkelbegreper" })
          }
        />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </DetaljPopover>
  ) : (
    <DetaljPopover
      forklaring={t("eiendomsareal_forklaring") as string}
      buttonAriaLabel={t("eiendomsareal_forklaring_arialabel") as string}
    >
      <OverskriftForEiendomsdetaljer>{t("eiendomsareal_overskrift")}</OverskriftForEiendomsdetaljer>
      {teigareal !== -1 ? (
        <FeltverdiForEiendomsdetaljer
          fieldValue={
            teigareal
              ? t("areal", { ns: "nøkkelbegreper", val: teigareal })
              : t("ikke_beregnet", { ns: "nøkkelbegreper" })
          }
        />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default EiendomsarealDetalj
