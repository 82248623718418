import { formaterMedKommaOgOg } from "../../../utils/formateringUtils"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import { Avlopstype, Energikildetype, Oppvarmingstype, Vannforsyningstype } from "./types/Enums"
import { EgenregistreringSchema, Felter, FelterLabels } from "./types/schema"

export const getModalLabel = (field: keyof EgenregistreringSchema): FelterLabels => {
  switch (field) {
    case "byggeaar":
      return "Byggeår"
    case "bruksareal":
      return "Bruksareal"
    case "energikilder":
      return "Energikilder"
    case "oppvarminger":
      return "Oppvarmingstyper"
    case "vannforsyning":
      return "Vannforsyning"
    case "avlop":
      return "Avløp"
    default:
      return ""
  }
}

export const visFeltverdi = (key: Felter, egenregistreringForBygning: EgenregistreringBygning) => {
  switch (key) {
    case "byggeaar":
      return egenregistreringForBygning.byggeaar?.data ?? ikkeRegistrertVerdi
    case "bruksareal":
      return egenregistreringForBygning.bruksareal?.data
        ? `${egenregistreringForBygning.bruksareal.data} m²`
        : ikkeRegistrertVerdi
    case "energikilder": {
      const energikilder = egenregistreringForBygning.bruksenheter[0].energikilder || []
      return energikilder.length > 0
        ? formaterMedKommaOgOg(energikilder.map(energikilde => Energikildetype[energikilde.data]))
        : ikkeRegistrertVerdi
    }
    case "oppvarminger": {
      const oppvarmingstyper = egenregistreringForBygning.bruksenheter[0].oppvarminger || []
      return oppvarmingstyper.length > 0
        ? formaterMedKommaOgOg(oppvarmingstyper.map(oppvarming => Oppvarmingstype[oppvarming.data]))
        : ikkeRegistrertVerdi
    }
    case "vannforsyning":
      return egenregistreringForBygning.vannforsyning?.data
        ? Vannforsyningstype[egenregistreringForBygning.vannforsyning.data]
        : ikkeRegistrertVerdi
    case "avlop":
      return egenregistreringForBygning.avlop?.data
        ? Avlopstype[egenregistreringForBygning.avlop.data]
        : ikkeRegistrertVerdi
    default:
      return ikkeRegistrertVerdi
  }
}

export const ikkeRegistrertVerdi = "---"
