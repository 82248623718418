import { Text, TextProps } from "@kvib/react"
import { useFontStyles } from "../fontStyles"

interface BrødtekstProps extends TextProps {
  children: React.ReactNode
  litenVariant?: boolean
}

export const Brødtekst = ({ children, litenVariant, ...props }: BrødtekstProps) => {
  const { getFontSize, getFontWeight } = useFontStyles()

  const fontSize = litenVariant ? getFontSize("LitenBrødtekst") : getFontSize("Brødtekst")
  const fontWeight = litenVariant ? getFontWeight("LitenBrødtekst") : getFontWeight("Brødtekst")

  return (
    <Text fontSize={fontSize} fontWeight={fontWeight} {...props}>
      {children}
    </Text>
  )
}
