import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { api } from "../../shared-api/router"

export const useKartutsnitt = (
  bredde: number | null,
  høyde: number | null,
  queryKeyId?: string,
  matrikkelnummer?: Matrikkelnummer,
) => {
  const isValidSize = bredde !== null && høyde !== null
  const queryKey = ["kartutsnitt".concat(queryKeyId ? `-${queryKeyId}` : ""), matrikkelnummer]

  const kartutsnittQuery = useQuery<string | null>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    staleTime: konverterFraMinutterTilMillisekunder(30),
    queryFn: () => api.kartutsnitt.hentKartutsnitt(matrikkelnummer!, bredde!, høyde!),
    enabled: !!matrikkelnummer && isValidSize,
  })

  return kartutsnittQuery
}
