import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import useEgenregistrering from "../api/useEgenregistrering"
import useErTomVerdi from "../hooks/useErTomVerdi"
import { EgenregistreringSchema, Felter, FelterLabels, IkonForFelt } from "../types/schema"
import { visFeltverdi } from "../utils"

interface FjernVerdiFraEgenregistreringProps {
  felt: { key: Felter; label: FelterLabels; icon: IkonForFelt }
  handleSubmit: (oppdatertEgenregistrering: EgenregistreringSchema) => void
}

const FjernVerdiFraEgenregistrering = (props: FjernVerdiFraEgenregistreringProps) => {
  const { data: egenregistreringForBygning, isSuccess } = useEgenregistrering()
  const isTomVerdi = useErTomVerdi()

  const { felt, handleSubmit } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formContext = useFormContext<EgenregistreringSchema>()

  if (!isSuccess) return null

  if (isTomVerdi(felt.key)) {
    return null
  }

  return (
    <>
      <Button
        colorScheme="red"
        leftIcon="delete"
        onClick={onOpen}
        aria-label={`Fjern egenregistrert verdi for ${felt.label}`}
        float="right"
        variant="tertiary"
      >
        Fjern
      </Button>

      <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fjern egenregistrert verdi?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Brødtekst>
              Du er i ferd med å fjerne følgende egenregistrerte verdi:
              <Brødtekst mt="10px" mb="10px">
                <strong>
                  {felt.label}: {visFeltverdi(felt.key, egenregistreringForBygning)}
                </strong>
              </Brødtekst>
              <Brødtekst>Er du sikker på at du vil gå videre?</Brødtekst>
            </Brødtekst>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Avbryt
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                formContext.setValue(felt.key, null)
                formContext.handleSubmit(form => {
                  handleSubmit(form)
                })()
                onClose()
              }}
            >
              Ja, fjern verdien
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FjernVerdiFraEgenregistrering
