import { Box, IconButton, Popover, PopoverTrigger, Tooltip } from "@kvib/react"
import PopoverInfo from "../../eiendom/components/PopoverInfo"
import { useScreenView } from "../hooks/useScreenview"

const PopoverikonMedTooltip = ({
  tooltipLabel,
  tooltipAriaLabel,
  iconButtonAriaLabel,
  icon,
  children,
}: {
  tooltipLabel: string
  tooltipAriaLabel: string
  iconButtonAriaLabel: string
  icon: "help" | "history"
  children: React.ReactElement
}) => {
  const isDesktop = useScreenView()
  const iconColor = icon === "help" ? "gray.600" : "green.500"
  return (
    <Popover>
      <Box display="inline-block">
        {isDesktop ? (
          <Tooltip label={tooltipLabel} aria-label={tooltipAriaLabel}>
            <Box>
              <PopoverTrigger>
                <IconButton
                  onFocus={e => e.preventDefault()}
                  aria-label={iconButtonAriaLabel}
                  icon={icon}
                  color={iconColor}
                  variant="tertiary"
                  iconFill={icon === "help"}
                  onClick={e => e.stopPropagation()}
                />
              </PopoverTrigger>
            </Box>
          </Tooltip>
        ) : (
          <PopoverTrigger>
            <IconButton
              onFocus={e => e.preventDefault()}
              aria-label={iconButtonAriaLabel}
              icon={icon}
              iconFill
              colorScheme="gray"
              variant="tertiary"
              onClick={e => e.stopPropagation()}
            />
          </PopoverTrigger>
        )}
        <PopoverInfo>{children}</PopoverInfo>
      </Box>
    </Popover>
  )
}

export default PopoverikonMedTooltip
