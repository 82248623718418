import { useQuery } from "@tanstack/react-query"
import { usePostHog } from "posthog-js/react"
import { konverterFraMinutterTilMillisekunder } from "../../../../utils/konverterFraMinutterTilMillisekunder"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import { Eiendom } from "../types/Eiendom"

export const useEiendommer = () => {
  const posthog = usePostHog()
  const eiendommerQuery = useQuery<Eiendom[], ApiError>({
    queryKey: ["eiendommer"],
    staleTime: konverterFraMinutterTilMillisekunder(15),
    queryFn: api.eiendommer.hentEiendommer,
    throwOnError: err => err.type !== "FANT_INGEN_EIENDOMMER",
  })

  if (!localStorage.getItem("personProperties")) {
    if (eiendommerQuery.isSuccess) {
      posthog.setPersonProperties({
        antallEiendommer: eiendommerQuery.data.length,
        eiendommer: eiendommerQuery.data.map(eiendom => {
          return {
            kommunenavn: eiendom.kommunenavn,
            matrikkelenhetstype: eiendom.matrikkelenhetstype,
            bruksenhetsnummer: eiendom.adresser.vegadresser?.map(
              vegadresse => vegadresse.bruksenhetsnummer,
            ),
          }
        }),
      })
      localStorage.setItem("personProperties", "true")
    }
  }
  return eiendommerQuery
}
