import { apiRoute } from "../../../../environments"
import { ApiError } from "../../../core/error-handling/ApiError"
import { DetaljertEiendomResponse } from "../../../shared-api/types/ApiResponseTypes"
import { mapToDetaljertEiendomFromApiResponse } from "./mapApiResponseToDetaljertEiendom"

export const eiendomRouter = {
  hentEiendom: async (matrikkelenhetId: string) => {
    const res = await fetch(`${apiRoute}/eiendommer/${matrikkelenhetId}`)

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const data: DetaljertEiendomResponse = await res.json()
    return mapToDetaljertEiendomFromApiResponse(data)
  },
}
