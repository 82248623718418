import { apiRoute } from "../../../environments"
import { ApiError } from "../../core/error-handling/ApiError"
import { Bygning } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { BygningskoordinaterResponse, ErrorResponse } from "../../shared-api/types/ApiResponseTypes"
import { Teigpolygon } from "../types/Kartutsnitt"

export const kartutsnittRouter = {
  hentKartutsnitt: (matrikkelnummer: Matrikkelnummer, bredde: number, høyde: number) => {
    let url = `${apiRoute}/kartutsnitt/eiendom?knr=${matrikkelnummer.kommunenummer}&gnr=${matrikkelnummer.gardsnummer}&bnr=${matrikkelnummer.bruksnummer}&fnr=${matrikkelnummer.festenummer}&snr=${matrikkelnummer.seksjonsnummer}`

    if (bredde && høyde) {
      url += `&imgX=${bredde}&imgY=${høyde}&dpr=${window.devicePixelRatio}`
    }

    return new Promise<string | null>((resolve, reject) => {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/octet-stream, application/json",
        },
      })
        .then(async response => {
          if (!response.ok) {
            if (response.status === 404) {
              reject(null)
              return
            }
            const body: ErrorResponse = await response.json()
            reject(new ApiError(body))
            return
          }
          resolve(await response.text())
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  hentKoordinaterForBygningerPåEiendom: (
    matrikkelnummer: Matrikkelnummer,
    bygninger: Bygning[],
    bredde: number,
    høyde: number,
  ) => {
    const url = `${apiRoute}/koordinater/bygninger`

    const bygningsMap = bygninger
      .filter(bygning => bygning.representasjonspunkt)
      .map(bygning => {
        return {
          byggId: bygning.byggId,
          representasjonspunkt: bygning.representasjonspunkt,
        }
      })

    const body = {
      matrikkelenhetMedBygningskoordinater: {
        matrikkelnummer: matrikkelnummer,
        bygningskoordinater: bygningsMap,
      },
      kartutsnittsinnstillinger: {
        x: bredde > 0 ? bredde : undefined,
        y: høyde > 0 ? høyde : undefined,
      },
    }

    return new Promise<BygningskoordinaterResponse | null>((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then(async response => {
          if (!response.ok) {
            if (response.status === 404) {
              resolve(null)
              return
            }
            const body: ErrorResponse = await response.json()
            reject(new ApiError(body))
            return
          }
          return response.json()
        })
        .then(bygningsmarkører => {
          resolve(bygningsmarkører)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  hentKoordinaterForTeigerPåEiendom: (
    matrikkelnummer: Matrikkelnummer,
    bredde: number,
    høyde: number,
  ) => {
    const url = `${apiRoute}/koordinater/teiger`

    const body = {
      matrikkelnummer: matrikkelnummer,
      kartutsnittsinnstillinger: {
        x: bredde > 0 ? bredde : undefined,
        y: høyde > 0 ? høyde : undefined,
      },
    }

    return new Promise<Teigpolygon[] | null>((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then(async response => {
          if (!response.ok) {
            if (response.status === 404) {
              resolve(null)
              return
            }
            const body: ErrorResponse = await response.json()
            reject(new ApiError(body))
            return
          }
          return response.json()
        })
        .then(bygningsmarkører => {
          resolve(bygningsmarkører)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
