export const energikildeMapping = {
  " ": "Ikke registrert",
  E: "Elektrisitet",
  O: "Olje/parafin",
  B: "Biobrensel",
  S: "Solenergi",
  V: "Varmepumpe",
  G: "Gass",
  F: "Fjernvarme",
  A: "Annen energikilde",
} as const

export type Energikildekode = keyof typeof energikildeMapping
