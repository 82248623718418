import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { api } from "../../shared-api/router"

const useTeigpolygon = (
  bredde: number | null,
  høyde: number | null,
  queryKeyId?: string,
  matrikkelnummer?: Matrikkelnummer,
) => {
  const isValidSize = bredde !== null && høyde !== null
  const queryKey = [
    "teigpolygon".concat(queryKeyId ? `-${queryKeyId}` : ""),
    matrikkelnummer,
    bredde,
    høyde,
  ]
  const teigpolygonQuery = useQuery({
    queryKey,
    staleTime: konverterFraMinutterTilMillisekunder(30),
    queryFn: () =>
      api.kartutsnitt.hentKoordinaterForTeigerPåEiendom(matrikkelnummer!, bredde!, høyde!),
    throwOnError: true,
    enabled: !!matrikkelnummer && isValidSize,
  })

  return teigpolygonQuery
}

export default useTeigpolygon
