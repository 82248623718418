import { Box, Container, ContainerProps, Flex } from "@kvib/react"

const Section = (props: ContainerProps) => {
  const horizontalPadding = { base: "16px", md: "40px", lg: "120px", xl: "160px" }
  return (
    <Container minW="100%" pl={horizontalPadding} pr={horizontalPadding} {...props}>
      <Flex justifyContent="center">
        <Box w="100%" maxW="1120px">
          {props.children}
        </Box>
      </Flex>
    </Container>
  )
}

export default Section
