import { useTheme } from "@kvib/react"
import { useMediaQuery } from "react-responsive"

type DeviceTypes = "isDesktop" | "isTablet" | "isMobile"

type ScreenTypes = {
  [Device in DeviceTypes]: boolean
}

export const useScreenView = (): ScreenTypes => {
  const theme = useTheme()

  const isDesktop = useMediaQuery({ query: `(min-width: ${theme.breakpoints.lg})` })
  const isTablet = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`,
  })
  const isMobile = useMediaQuery({ query: `(max-width: ${theme.breakpoints.md})` })

  return { isDesktop, isTablet, isMobile }
}
