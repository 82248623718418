import { Grid, Stack } from "@kvib/react"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { formaterVegadresserForBygningskort } from "../../../eiendomsoversikt/utils/adresseForEiendomUtils"
import {
  bygningAria,
  getBruksenhetIds,
  getBruksenheterForBygning,
  getBygningskoordinat,
  getVegadresserPåBygning,
  sorterPåBygningstype,
} from "../utils/bygningerUtils"
import { Bygningstypekode, bygningstyperMapper } from "../utils/bygningstypeUtil"

import { useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useBygningskoordinater from "../../../../kartutsnitt/api/useBygningskoordinater"
import KartutsnittMedBygningskoordinat from "../../../../kartutsnitt/components/KartutsnittMedBygningskoordinat"
import { DetaljertEiendom } from "../../types/DetaljertEiendom"
import Bygningskort from "./Bygningskort"

const BygningskortMedKartutsnitt = ({ eiendom }: { eiendom: DetaljertEiendom }) => {
  const { isMobile } = useScreenView()
  const { t } = useTranslation("bygningskort")

  const [imgWidth, setImgWidth] = useState<number | null>(null)
  const [imgHeight, setImgHeight] = useState<number | null>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  useLayoutEffect(() => {
    if (imgRef.current) {
      const width = imgRef.current.clientWidth
      const height = imgRef.current.clientHeight
      setImgWidth(width)
      setImgHeight(height)
    }
  }, [])

  const { data: koordinater } = useBygningskoordinater(
    imgWidth,
    imgHeight,
    eiendom.matrikkelnummer,
    eiendom.bygningerPåEiendom,
  )

  return (
    <Stack
      data-cy="bygningskort-liste"
      w="100%"
      justifyContent="center"
      gap="14px"
      mt={{
        base: "12px",
        lg: "24px",
      }}
      aria-label={bygningAria(eiendom.bygningerPåEiendom) as string}
    >
      {sorterPåBygningstype(eiendom.bygningerPåEiendom).map((bygning, index) => {
        const bruksenheter = getBruksenheterForBygning(eiendom, bygning)
        const bruksenhetIds = getBruksenhetIds(bruksenheter)
        const vegadresser = eiendom.adresser.vegadresser
        const vegadresserPåBygning = getVegadresserPåBygning(vegadresser, bruksenhetIds)
        const bygningskoordinat = getBygningskoordinat(koordinater, bygning)

        const Kartutsnitt = () => (
          <KartutsnittMedBygningskoordinat
            ref={index === 0 ? imgRef : null}
            eiendom={eiendom}
            bygningskoordinat={bygningskoordinat}
            representasjonspunkt={bygning.representasjonspunkt}
          />
        )

        return (
          <Grid key={bygning.byggId} gap="2rem" templateColumns={isMobile ? "100%" : "3fr 2fr"}>
            <Bygningskort
              key={bygning.byggId}
              bygning={bygning}
              vegadresse={
                vegadresserPåBygning
                  ? formaterVegadresserForBygningskort(vegadresserPåBygning)
                  : (t("ingen_adresse") as string)
              }
              aria-label={
                t("bygningskort_arialabel", {
                  index: index + 1,
                  bygningstype:
                    bygningstyperMapper[bygning.bygningstype.substring(0, 2) as Bygningstypekode],
                }) as string
              }
              role="group"
              eiendom={eiendom}
              w="100%"
              maxW="100%"
            >
              {isMobile && <Kartutsnitt />}
            </Bygningskort>
            {!isMobile && <Kartutsnitt />}
          </Grid>
        )
      })}
    </Stack>
  )
}

export default BygningskortMedKartutsnitt
