import { TextProps } from "@kvib/react"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useFontStyles } from "../../../core/text/fontStyles"

interface Props extends TextProps {
  fieldValue?: string
  id?: string
  ariaLabel?: string
}

const FeltverdiForEiendomsdetaljer = ({ fieldValue, id, ariaLabel }: Props) => {
  const { getFontSize } = useFontStyles()
  return (
    <Brødtekst fontWeight="bold" fontSize={getFontSize("h3")} id={id} aria-label={ariaLabel}>
      {fieldValue}
    </Brødtekst>
  )
}

export default FeltverdiForEiendomsdetaljer
