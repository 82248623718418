import { BoxProps, Flex, Icon, Stack } from "@kvib/react"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../../core/text/components/Overskrift"
import { bygningstypeIkonMapper } from "../../../eiendomsoversikt/utils/eiendomUtils"
import { Bygningstypekode, bygningstyperMapper } from "../utils/bygningstypeUtil"

interface Props extends BoxProps {
  bygningstype: string
  vegadresse?: string
  erSeksjon: boolean
}

const BygningstypeOgVegadresseOverskrift = ({
  erSeksjon,
  bygningstype,
  vegadresse,
  ...props
}: Props) => {
  const { isDesktop } = useScreenView()
  const iconSize = isDesktop ? 50 : 45
  const iconWrapper = `${iconSize + 6}px`

  const title = erSeksjon
    ? `Seksjon i ${bygningstyperMapper[bygningstype.substring(0, 2) as Bygningstypekode].toLowerCase()}`
    : bygningstyperMapper[bygningstype.substring(0, 2) as Bygningstypekode]

  return (
    <Flex gap="12px" alignItems="center" w="100%" {...props}>
      <Flex
        bg="green.100"
        borderRadius="50%"
        minW={iconWrapper}
        maxW={iconWrapper}
        minH={iconWrapper}
        maxH={iconWrapper}
        justifyContent="center"
      >
        <Icon
          isFilled
          icon={bygningstypeIkonMapper(bygningstype.substring(0, 2))}
          size={iconSize}
          color="#156630"
          ariaIsHidden
        />
      </Flex>
      <Stack gap="2px" textAlign="left">
        <Overskrift
          variant="h3"
          noOfLines={4}
          whiteSpace="normal"
          wordBreak="break-word"
          h="fit-content"
        >
          {title}
        </Overskrift>
        <Brødtekst litenVariant>{vegadresse}</Brødtekst>
      </Stack>
    </Flex>
  )
}

export default BygningstypeOgVegadresseOverskrift
