import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"
const Eierandeldetalj = ({ eierandel }: { eierandel?: string }) => {
  const { t } = useTranslation("detaljertInnsyn")

  return (
    <DetaljPopover
      forklaring={t("eierandel_forklaring") as string}
      buttonAriaLabel={t("eierandel_forklaring_arialabel") as string}
    >
      <OverskriftForEiendomsdetaljer>{t("eierandel_overskrift")}</OverskriftForEiendomsdetaljer>
      {eierandel ? (
        <FeltverdiForEiendomsdetaljer fieldValue={eierandel} />
      ) : (
        <Skeleton w="60%" h="24px" />
      )}
    </DetaljPopover>
  )
}

export default Eierandeldetalj
