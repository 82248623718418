import { Box, Flex } from "@kvib/react"
import { ReactNode } from "react"
import { Brødtekst } from "../text/components/Brødtekst"
import { Overskrift } from "../text/components/Overskrift"

interface ErrorDisplayProps {
  alertTitle: string
  icon: ReactNode
  alertMessage?: string
  actionButton?: ReactNode
}

export const ErrorDisplay = (props: ErrorDisplayProps) => {
  document.title = props.alertTitle

  return (
    <Box
      m="auto"
      as={Flex}
      flexDir="column"
      textAlign="center"
      alignItems="center"
      w="fit-content"
      pb="40px"
    >
      {props.icon}
      <Box p="24px">
        <Overskrift color="gray.500" variant="h1">
          {props.alertTitle}
        </Overskrift>
        {props.alertMessage && <Brødtekst>{props.alertMessage}</Brødtekst>}
      </Box>
      {props.actionButton}
    </Box>
  )
}
