import { Overskrift } from "../../../core/text/components/Overskrift"
import { useFontStyles } from "../../../core/text/fontStyles"

const OverskriftForEiendomsdetaljer = ({ children }: { children: string }) => {
  const { getFontSize } = useFontStyles()

  return (
    <Overskrift
      fontWeight="normal"
      fontSize={getFontSize("LitenBrødtekst")}
      variant="h3"
      color="gray.600"
    >
      {children}
    </Overskrift>
  )
}

export default OverskriftForEiendomsdetaljer
