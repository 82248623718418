import { useToast } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { getAuthUrlAndRedirect } from "../authentication"

export const useHandleLogin = () => {
  const innloggingFeiletToast = useInnloggingFeiletToast()

  const handleLogin = () => {
    getAuthUrlAndRedirect().catch(() => innloggingFeiletToast())
  }

  return handleLogin
}

const useInnloggingFeiletToast = () => {
  const toast = useToast()
  const { t } = useTranslation("errormeldinger")

  const showToast = () => {
    toast({
      title: t("kan_ikke_logge_inn_tittel"),
      description: t("kan_ikke_logge_inn_beskrivelse"),
      status: "error",
      duration: 8000,
      isClosable: true,
    })
  }

  return showToast
}
