import { Stack } from "@kvib/react"
import { useTranslation } from "react-i18next"
import bygning from "../../assets/bygning.svg"
import seksjon from "../../assets/seksjon.svg"
import teig from "../../assets/teig.svg"
import Section from "../core/components/layout/Section"
import { Overskrift } from "../core/text/components/Overskrift"
import ForklaringAccordionWrapper from "./components/ForklaringAccordionWrapper"

const Datafelter = () => {
  const { t } = useTranslation(["datafelter"])
  const eiendomsforklaringer = t("datafelter_eiendom", { returnObjects: true })
  const bygningsforklaringer = t("datafelter_bygning", { returnObjects: true })
  const bruksenhetforklaringer = t("datafelter_bruksenhet", { returnObjects: true })
  return (
    <Section>
      <Overskrift variant="h1" children={t("hva_betyr_de_forskjellige_datafeltene")} />
      <Stack gap="48px" mt="48px" mb="150px">
        <ForklaringAccordionWrapper
          translations={eiendomsforklaringer}
          tittel={t("eiendom")}
          ikon={teig}
        />
        <ForklaringAccordionWrapper
          translations={bygningsforklaringer}
          tittel={t("bygning")}
          ikon={bygning}
        />
        <ForklaringAccordionWrapper
          translations={bruksenhetforklaringer}
          tittel={t("bruksenhet")}
          ikon={seksjon}
        />
      </Stack>
    </Section>
  )
}

export default Datafelter
