import { Center } from "@kvib/react"
import { useTranslation } from "react-i18next"
import Section from "../../core/components/layout/Section"
import StackMedGap from "../../core/components/layout/StackMedGap"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { Overskrift } from "../../core/text/components/Overskrift"
import { AvsnittMedTittel } from "../../localization/pages/hjelpOgStøtte"
import { InfoCard } from "../components/InfoCard"

const PersonvernOgSikkerhetInfoside = () => {
  const { t } = useTranslation("personvernOgSikkerhet", { lng: "nn-NO" })

  return (
    <Section pb={["20px", "160px"]}>
      <Center>
        <StackMedGap>
          <Overskrift variant="h1">{t("overskrift")}</Overskrift>
          <InfoCard
            cardHeader={
              (t("personopplysninger", { returnObjects: true }) as AvsnittMedTittel).tittel
            }
          >
            <Brødtekst>
              {(t("personopplysninger", { returnObjects: true }) as AvsnittMedTittel).avsnitt[0]}
            </Brødtekst>
            <br />
            <Brødtekst>
              {(t("personopplysninger", { returnObjects: true }) as AvsnittMedTittel).avsnitt[1]}
            </Brødtekst>
            <br />
            <Brødtekst>
              {(t("personopplysninger", { returnObjects: true }) as AvsnittMedTittel).avsnitt[2]}
            </Brødtekst>
            <br />
            <EksternLenke tilAdresse="https://www.kartverket.no/om-kartverket/personvern">
              {t("mer_om_personvernerklæring_link")}
            </EksternLenke>
          </InfoCard>
          <InfoCard
            cardHeader={(t("innlogging", { returnObjects: true }) as AvsnittMedTittel).tittel}
          >
            <Brødtekst>
              {(t("innlogging", { returnObjects: true }) as AvsnittMedTittel).avsnitt[0]}
            </Brødtekst>
            <br />
            <EksternLenke tilAdresse="https://eid.difi.no/nb/sikkerhet-og-personvern">
              {t("mer_om_IDporten_innlogging_link")}
            </EksternLenke>
          </InfoCard>
        </StackMedGap>
      </Center>
    </Section>
  )
}

export default PersonvernOgSikkerhetInfoside
