import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Flex,
  IconButton,
} from "@kvib/react"
import { useLocation, useNavigate } from "react-router-dom"
import { useScreenView } from "../../../../core/hooks/useScreenview"
import { useEgenregistreringIsEnabled } from "../../../registrering/api/useEgenregistreringIsEnabled"
import { DetaljertEiendom } from "../../types/DetaljertEiendom"
import { Bygning } from "../types/Bygning"
import { BygningskortInnhold } from "./BygningskortInnhold"
import BygningstypeOgVegadresseOverskrift from "./BygningstypeOgAdresseOverskrift"

interface Props extends CardProps {
  bygning: Bygning
  eiendom: DetaljertEiendom
  vegadresse?: string
}

const Bygningskort = ({ bygning, vegadresse, eiendom, children, ...props }: Props) => {
  const { isMobile } = useScreenView()
  const { data: kanEgenregistrere } = useEgenregistreringIsEnabled()
  const bruksenhetsIds = eiendom?.bruksenheterPåEiendom.filter(
    bruksenhet => bruksenhet.byggId === bygning.byggId,
  )

  const { pathname } = useLocation()

  // Sjekker at det finnes nøyaktig én bruksenhet
  const nøyaktigEnBruksenhetIBygning = bruksenhetsIds && bruksenhetsIds.length === 1

  const EgenregistreringKnapp = () => {
    return (
      <IconButton
        icon="edit"
        aria-label="Registrer opplysninger om bygning"
        onClick={() => {
          const url = `${pathname}/${bygning.byggId}`
          navigate(url)
        }}
        variant="tertiary"
        ml="auto"
        pt="30px"
      />
    )
  }

  const navigate = useNavigate()
  const BygningskortAccordionForMobil = () => (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem p={0} as={Card} border="none" {...props}>
        {kanEgenregistrere && nøyaktigEnBruksenhetIBygning && (
          <Flex>
            <EgenregistreringKnapp />
          </Flex>
        )}
        <h3>
          <AccordionButton
            minH="120px"
            _hover={{
              bg: "inherit",
              borderRadius: "inherit",
            }}
            _expanded={{
              borderRadius: "inherit",
            }}
          >
            <BygningstypeOgVegadresseOverskrift
              erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
              bygningstype={bygning.bygningstype}
              vegadresse={vegadresse}
            />
            <AccordionIcon />
          </AccordionButton>
        </h3>
        <AccordionPanel mb="16px" p={0}>
          {children}
          <BygningskortInnhold
            nøyaktigEnBruksenhetIBygning={nøyaktigEnBruksenhetIBygning}
            eiendom={eiendom}
            bygning={bygning}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )

  return isMobile ? (
    <BygningskortAccordionForMobil />
  ) : (
    <Card {...props}>
      <CardHeader as={Flex}>
        <BygningstypeOgVegadresseOverskrift
          erSeksjon={eiendom.matrikkelenhetstype === "Eierseksjon"}
          bygningstype={bygning.bygningstype}
          vegadresse={vegadresse}
        />
        {kanEgenregistrere && nøyaktigEnBruksenhetIBygning && <EgenregistreringKnapp />}
      </CardHeader>
      <CardBody pt="0">
        <BygningskortInnhold
          nøyaktigEnBruksenhetIBygning={nøyaktigEnBruksenhetIBygning}
          eiendom={eiendom}
          bygning={bygning}
        />
      </CardBody>
    </Card>
  )
}

export default Bygningskort
