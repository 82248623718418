import { SimpleGrid } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { DetaljertEiendom } from "../types/DetaljertEiendom"
import Eierandeldetalj from "./Eierandeldetalj"
import Matrikkelenhetstypedetalj from "./Matrikkelenhetstypedetalj"
import Matrikkelnummerdetalj from "./Matrikkelnummerdetalj"

const NøkkelinfoOmEiendommen = ({ eiendom }: { eiendom: DetaljertEiendom | undefined }) => {
  const { t } = useTranslation("nøkkelbegreper")
  const { isDesktop } = useScreenView()

  return (
    <SimpleGrid gap={4} gridTemplateColumns={isDesktop ? "1fr 1fr 1fr 1fr" : "1fr"}>
      <Matrikkelnummerdetalj key="matrikkelnummer" matrikkelnummer={eiendom?.matrikkelnummer} />
      <Matrikkelenhetstypedetalj
        key="matrikkelenhetstype"
        matrikkelenhetstype={eiendom?.matrikkelenhetstype}
      />
      <Eierandeldetalj
        key="eierandel"
        eierandel={eiendom?.eierandel === null ? t("ikke_registrert") : eiendom?.eierandel}
      />
    </SimpleGrid>
  )
}

export default NøkkelinfoOmEiendommen
