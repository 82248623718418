import { Outlet, useLocation, useMatch } from "react-router-dom"
import { Landingsside } from "../core/components/layout/Landingsside"
import ErrorHandler from "../core/error-handling/ErrorHandler"
import useIsInnlogget from "../core/hooks/useIsInnlogget"

const ProtectedRoute = () => {
  const { isSuccess, isPending, data: isInnloggetData, error } = useIsInnlogget()
  const isInnlogget = isSuccess && isInnloggetData

  const location = useLocation()
  const match = useMatch(location.pathname)

  if (isPending) return null

  if (error && error.type === "FANT_IKKE_PERSON") return <ErrorHandler apiError={error} />

  if (match?.pathname != "/" && !isInnlogget) return <ErrorHandler apiError={error ?? undefined} />

  return isInnlogget ? <Outlet /> : <Landingsside />
}

export default ProtectedRoute
