import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { Matrikkelnummer } from "./features/eiendom/eiendomsoversikt/types/Eiendom"
import { formaterMatrikkelnummer } from "./features/eiendom/eiendomsoversikt/utils/eiendomUtils"
import { resources } from "./features/localization/translations"

i18n.use(initReactI18next).init({
  react: {
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "li", "ul"],
  },
  resources: resources,
  lng: "nb-NO",
  fallbackLng: "nb-NO",
  interpolation: {
    escapeValue: false,
  },
})
i18n.services.formatter?.addCached("matrikkelnummer", () => (value: Matrikkelnummer) => {
  return formaterMatrikkelnummer(value)
})
