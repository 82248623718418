import { apiRoute } from "../../../environments"
import { ApiError } from "../../core/error-handling/ApiError"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { ErrorResponse } from "../../shared-api/types/ApiResponseTypes"
import { Polygoner } from "../types/InteraktivtKart"

export const interaktivtKartRouter = {
  hentTeigpolygonerForEiendom: (matrikkelnummer: Matrikkelnummer) => {
    const url = `${apiRoute}/koordinater/teigpolygoner`

    return new Promise<Polygoner | null>((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(matrikkelnummer),
      })
        .then(async response => {
          if (!response.ok) {
            if (response.status === 404) {
              resolve(null)
              return
            }
            const body: ErrorResponse = await response.json()
            reject(new ApiError(body))
            return
          }
          return response.json()
        })
        .then(bygningsmarkører => {
          resolve(bygningsmarkører)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
