import { useParams } from "react-router-dom"
import { useEiendommer } from "../../eiendomsoversikt/api/useEiendommer"
import { useEiendom } from "../api/useEiendom"

const useCurrentEiendom = () => {
  const { eiendomsIndex } = useParams()
  const { data: eiendommer, isPending: isEiendommerPending } = useEiendommer()
  const index = Number(eiendomsIndex)
  const matrikkelenhetId = eiendommer?.find(e => e.eiendomsIndex === index)?.matrikkelenhetId
  const { data: eiendom } = useEiendom(matrikkelenhetId)

  const finnerIkkeEiendom = matrikkelenhetId === undefined

  return { eiendom, isEiendommerPending, finnerIkkeEiendom }
}

export default useCurrentEiendom
