import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { ApiError } from "../../core/error-handling/ApiError"
import { api } from "../../shared-api/router"
import { Kodelister } from "../../shared-api/types/ApiResponseTypes"

export const useKodelister = () => {
  const kodelisteQuery = useQuery<Kodelister, ApiError>({
    queryKey: ["kodelister"],
    staleTime: konverterFraMinutterTilMillisekunder(15),
    queryFn: api.kodelister.hentKodelister,
    throwOnError: err => err.type !== "UVENTET_FEIL",
  })

  return kodelisteQuery
}
