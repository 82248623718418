import { useTranslation } from "react-i18next"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { verboseMatrikkelnummer } from "../eiendomsoversikt/utils/eiendomUtils"
import DetaljPopover from "./DetaljPopover"

interface InfoPopoverProps {
  matrikkelnummer: string
}

export const MatrikkelnummerInfoPopover = (props: InfoPopoverProps) => {
  const matrikkeltekstId = "matrikkeltekst-" + props.matrikkelnummer
  const { t } = useTranslation("nøkkelbegreper")
  const matrikkelnummerId = "matrikkelnummer-" + props.matrikkelnummer
  const { isDesktop } = useScreenView()

  return (
    <DetaljPopover
      posthogName="matrikkelnummer_info_click"
      forklaring={t("matrikkelnummer_forklaring")}
      buttonAriaLabel={t("matrikkelnummer_forklaring_arialabel")}
    >
      <Brødtekst
        litenVariant
        id={matrikkeltekstId}
        color="gray.500"
        aria-hidden="true"
        lineHeight="20px"
      >
        {t("matrikkelnummer")}
      </Brødtekst>
      <Brødtekst
        id={matrikkelnummerId}
        aria-hidden="true"
        color="gray.500"
        aria-label={verboseMatrikkelnummer(props.matrikkelnummer)}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        lineHeight="24px"
        fontWeight="bold"
        fontSize={isDesktop ? "m" : "sm"}
      >
        {props.matrikkelnummer}
      </Brødtekst>
    </DetaljPopover>
  )
}
