import { Box, Image, Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import eiendomSvg from "../../../assets/eiendom.svg"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { DetaljertEiendom } from "../../eiendom/detaljert-eiendom/types/DetaljertEiendom"
import useTeigpolygoner from "../../interaktivtKart/api/useTeigpolygoner"
import { InteraktivtKart } from "../../interaktivtKart/InteraktivtKart"

const KartutsnittForDetaljertEiendom = ({ eiendom }: { eiendom?: DetaljertEiendom }) => {
  const { t } = useTranslation("eiendomskort")
  const representasjonspunkt = eiendom?.koordinat

  const { data: teigpolygoner, isSuccess } = useTeigpolygoner(eiendom?.matrikkelnummer)

  if (eiendom && !representasjonspunkt) {
    return (
      <Box
        p="64px"
        bg="green.50"
        borderRadius={8}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Brødtekst color="gray.600" align="center">
          {t("mangler_kartutsnitt_for_eiendommen_forklaring")}
        </Brødtekst>
        <Image
          src={eiendomSvg}
          transform="scaleX(-1)"
          opacity=".1"
          position="absolute"
          bottom="0"
          right="0"
          height="100%"
          pt="1rem"
          pl="2%"
          objectFit="contain"
        />
      </Box>
    )
  }

  return (
    <Skeleton isLoaded={isSuccess}>
      <Box aspectRatio={["4/3", "2/1", "2/1", "5/2"]}>
        <InteraktivtKart teigpolygoner={teigpolygoner ?? undefined} />
      </Box>
    </Skeleton>
  )
}

export default KartutsnittForDetaljertEiendom
