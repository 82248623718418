import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../../utils/konverterFraMinutterTilMillisekunder"
import { api } from "../../../shared-api/router"

export const useEgenregistreringIsEnabled = () => {
  const queryKey = ["feature-toogle", "egenregistrering"]

  const egenregistreringQuery = useQuery<boolean | null>({
    queryKey,
    staleTime: konverterFraMinutterTilMillisekunder(30),
    queryFn: () => api.egenregistrering.kanEgenregistrere(),
  })

  return egenregistreringQuery
}
