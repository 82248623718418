import { zodResolver } from "@hookform/resolvers/zod"
import { Table, TableContainer, Tbody, Td, Tr, useDisclosure } from "@kvib/react"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Section from "../../core/components/layout/Section"
import { Overskrift } from "../../core/text/components/Overskrift"
import useOpprettEgenregistrering from "./api/useOpprettEgenregistering"
import CircularIcon from "./components/CircularIcon"
import { EgenregistreringKnapp } from "./components/EgenregistreringKnapp"
import EgenregistreringModal from "./components/EgenregistreringModal"
import { EgenregistreringModalForAlleFelter } from "./components/EgenregistreringModalForAlleFelter"
import FjernVerdiFraEgenregistrering from "./components/FjernVerdiFraEgenregistrering"
import Registreringstidspunkt from "./components/Registreringstidspunkt"
import useErTomVerdi from "./hooks/useErTomVerdi"
import { EgenregistreringBygning } from "./types/Egenregistrering"
import {
  EgenregistreringSchema,
  Felter,
  FelterLabels,
  IkonForFelt,
  egenregistreringSchema,
} from "./types/schema"
import { ikkeRegistrertVerdi, visFeltverdi } from "./utils"

type ModalType = "ettFelt" | "alleFelter"

const EgenregistreringForm = ({
  egenregistreringForBygning,
}: {
  egenregistreringForBygning: EgenregistreringBygning
}) => {
  const isTomVerdi = useErTomVerdi()

  const bruksenhetId = egenregistreringForBygning.bruksenheter[0].bruksenhetId

  const { mutate: sendEgenregistrering, isPending } = useOpprettEgenregistrering()

  const queryClient = useQueryClient()

  const [inputField, setInputField] = useState<keyof EgenregistreringSchema | undefined>(undefined)
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const form = useForm<EgenregistreringSchema>({
    resolver: zodResolver(egenregistreringSchema),
    reValidateMode: "onChange",
  })

  const handleOpenModal = (type: ModalType, field?: keyof EgenregistreringSchema) => {
    setModalType(type)
    if (type === "ettFelt" && field) {
      setInputField(field)
    }
    onOpen()
  }

  const handleCloseModal = () => {
    setInputField(undefined)
    setModalType(undefined)
    form.reset()
    onClose()
  }

  const handleSubmit = (oppdatertEgenregistrering: EgenregistreringSchema) => {
    sendEgenregistrering(
      {
        eksisterendeEgenregistrering: egenregistreringForBygning,
        bygningId: egenregistreringForBygning.bygningId,
        bruksenhetsId: bruksenhetId.toString(),
        oppdatertEgenregistrering: oppdatertEgenregistrering,
      },
      {
        onSuccess: () => {
          handleCloseModal()
          queryClient.invalidateQueries({ queryKey: ["egenregistrering"] })
        },
      },
    )
  }

  const felter: Array<{ key: Felter; label: FelterLabels; icon: IkonForFelt }> = [
    { key: "byggeaar", label: "Byggeår", icon: "calendar_today" },
    { key: "bruksareal", label: "Bruksareal", icon: "square_foot" },
    { key: "energikilder", label: "Energikilder", icon: "lightbulb" },
    { key: "oppvarminger", label: "Oppvarmingstyper", icon: "mode_heat" },
    { key: "vannforsyning", label: "Vannforsyning", icon: "water_drop" },
    { key: "avlop", label: "Avløp", icon: "valve" },
  ]

  const tekstForEgenregistreringsknapp = (felt: {
    key: Felter
    label: FelterLabels
    icon: IkonForFelt
  }): string => {
    if (visFeltverdi(felt.key, egenregistreringForBygning) === ikkeRegistrertVerdi) {
      return `Registrer `
    }
    return `Endre `
  }

  return (
    <Section mb="24px">
      <Overskrift variant="h2" mb="24px">
        Egenregistrering for bygning
      </Overskrift>
      <FormProvider {...form}>
        <TableContainer>
          <form
            onSubmit={form.handleSubmit(
              handleSubmit,
              //handleSubmit blir bare kalt dersom valideringen i formet er vellykket.
              //Dersom man har glemt å vise erroren i UIet, vil ingenting tilsynelatende skje når du submitter.
              // onInvalid-callbacket til form.handleSubmit() håndterer dette tilfellet, og er en debug-hjelp til utvikler når mans gjør store strukturelle endringer i formet.
              err => {
                throw err
              },
            )}
          >
            <Table colorScheme="green">
              <Tbody>
                <Tr>
                  <Td colSpan={4}>
                    <EgenregistreringKnapp icon="add" onClick={() => handleOpenModal("alleFelter")}>
                      Registrer alle felter
                    </EgenregistreringKnapp>
                  </Td>
                </Tr>
                {felter.map(felt => (
                  <Tr key={felt.key}>
                    <Td display="flex" alignItems="center">
                      <CircularIcon icon={felt.icon} />
                      <Overskrift variant="h4">{felt.label}</Overskrift>
                    </Td>
                    <Td>{visFeltverdi(felt.key, egenregistreringForBygning)} </Td>
                    <Td>
                      <Registreringstidspunkt feltKey={felt.key} />
                    </Td>
                    <Td colSpan={3} textAlign="left">
                      <EgenregistreringKnapp
                        icon={isTomVerdi(felt.key) ? "add" : "edit"}
                        onClick={() => handleOpenModal("ettFelt", felt.key)}
                      >
                        {tekstForEgenregistreringsknapp(felt)}
                      </EgenregistreringKnapp>
                      <FjernVerdiFraEgenregistrering felt={felt} handleSubmit={handleSubmit} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </form>
        </TableContainer>
        {modalType === "ettFelt" && inputField && (
          <EgenregistreringModal
            felt={inputField}
            isOpen={isOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            isLoading={isPending}
          />
        )}
        {modalType === "alleFelter" && (
          <EgenregistreringModalForAlleFelter
            isOpen={isOpen}
            onClose={handleCloseModal}
            onSubmit={handleSubmit}
            isLoading={isPending}
          />
        )}
      </FormProvider>
    </Section>
  )
}

export default EgenregistreringForm
