import { useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import Section from "../../../core/components/layout/Section"
import { finnerIngenPathsSomMatcher } from "../../../core/error-handling/types/finnerIngenPathSomPatcher"
import { api } from "../../../shared-api/router"
import useCurrentEiendom from "../hooks/useCurrentEiendom"
import Bygningsdetaljer from "./Bygningsdetaljer"
import Eiendomsdetaljer from "./Eiendomsdetaljer"
import FlereOpplysninger from "./FlereOpplysninger"

const DetaljertEiendom = () => {
  const { eiendom, isEiendommerPending, finnerIkkeEiendom } = useCurrentEiendom()
  const queryClient = useQueryClient()

  const harBygningerPåEiendom =
    eiendom && eiendom.bygningerPåEiendom && eiendom.bygningerPåEiendom.length > 0

  const commonPaddingForSection = ["30px", "40px"]

  if (!isEiendommerPending && finnerIkkeEiendom) {
    throw finnerIngenPathsSomMatcher
  }

  useEffect(() => {
    if (eiendom?.bygningerPåEiendom) {
      eiendom.bygningerPåEiendom.forEach(bygning => {
        queryClient.prefetchQuery({
          queryKey: ["egenregistrering", bygning.byggId],
          queryFn: () => api.egenregistrering.hentEgenregistrering(bygning.byggId),
        })
      })
    }
  }, [eiendom?.bygningerPåEiendom, queryClient])

  return (
    <>
      <Section pt="12px" pb={commonPaddingForSection}>
        <Eiendomsdetaljer eiendom={eiendom} />
      </Section>
      {harBygningerPåEiendom && (
        <Section bg="green.50" pt={commonPaddingForSection} pb={commonPaddingForSection}>
          <Bygningsdetaljer eiendom={eiendom} />
        </Section>
      )}
      <Section pt={commonPaddingForSection} pb={commonPaddingForSection} bg="white">
        <FlereOpplysninger eiendom={eiendom} />
      </Section>
    </>
  )
}

export default DetaljertEiendom
