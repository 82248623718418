export const apiRoute = "/api"

export const apiRouteLogin = `${apiRoute}/auth/login`

export const apiRouteLogout = `${apiRoute}/auth/logout`

type Env = "localhost" | "dev" | "test" | "prod"

export function getEnv(): Env | undefined {
  const hostname = window.location.hostname
  if (hostname === "localhost") return "localhost"
  if (hostname.includes("egenreg-dev.kartverket-intern.cloud")) return "dev"
  if (hostname.includes("egenreg-staging.kartverket-intern.cloud")) return "test"
  if (hostname.includes("mineiendom.kartverket.no")) return "prod"
  // eslint-disable-next-line no-console
  console.warn(`Unknown hostname: "${hostname}". Can't determine environment.`)
  return undefined
}
