import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import {
  Avlopstype,
  Energikildetype,
  Oppvarmingstype,
  Vannforsyningstype,
  enumToOptions,
} from "../types/Enums"
import { EgenregistreringSchema } from "../types/schema"
import Dropdown from "./Dropdown"
import Flervalg from "./Flervalg"
import InputfeltTall from "./InputfeltTall"

interface EgenregistreringModalForAlleFelterProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EgenregistreringSchema) => void
  isLoading: boolean
}

export const EgenregistreringModalForAlleFelter = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: EgenregistreringModalForAlleFelterProps) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const handleSubmit = () => {
    formContext.handleSubmit(onSubmit)()
  }
  const skalViseTomtSkjemaFeilmelding = () => {
    const isSubmitted = formContext.formState.isSubmitted
    return (
      isSubmitted && Object.values(formContext.formState.errors).every(err => err.type === "custom")
    )
  }

  const renderError = (felt: keyof EgenregistreringSchema) =>
    formContext.getFieldState(felt).isDirty &&
    formContext.getFieldState(felt).error?.type !== "custom" &&
    formContext.formState.errors[felt] && (
      <Alert status="error" variant="top-accent">
        <AlertIcon />
        <AlertDescription>{formContext.getFieldState(felt).error?.message}</AlertDescription>
      </Alert>
    )

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Egenregistrering for bygning</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={8}>
            <InputfeltTall
              name="byggeaar"
              felt="byggeaar"
              label="Byggeår"
              placeholder="F. eks 2023"
            />
            {renderError("byggeaar")}

            <InputfeltTall
              name="bruksareal"
              felt="bruksareal"
              label="Bruksareal"
              placeholder="Oppgis i kvadratmeter"
            />
            {renderError("bruksareal")}

            <Flervalg
              name="energikilder"
              label="Energikilder"
              options={enumToOptions(Energikildetype)}
              register={formContext.register}
            />
            {renderError("energikilder")}

            <Flervalg
              name="oppvarminger"
              label="Oppvarmingstyper"
              options={enumToOptions(Oppvarmingstype)}
              register={formContext.register}
            />
            {renderError("oppvarminger")}

            <Dropdown
              placeholder="Velg vannforsyning"
              label="Vannforsyning"
              options={enumToOptions(Vannforsyningstype)}
              name="vannforsyning"
              register={formContext.register}
            />
            {renderError("vannforsyning")}

            <Dropdown
              placeholder="Velg avløp"
              label="Avløp"
              options={enumToOptions(Avlopstype)}
              name="avlop"
              register={formContext.register}
            />
            {renderError("avlop")}
          </Stack>
          {skalViseTomtSkjemaFeilmelding() && (
            <Alert mt="24px" status="error" variant="top-accent">
              <AlertIcon />
              <AlertDescription>Vennligst fyll inn minst én gyldig verdi</AlertDescription>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Avbryt
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Send inn
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
