import { ReactIntegration, createReactRouterV6DataOptions } from "@grafana/faro-react"
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk"
import { matchRoutes } from "react-router-dom"
import { getEnv } from "./environments"

const faro = initializeFaro({
  app: {
    name: "egenreg-frontend",
    environment: getEnv(),
  },
  url: "https://faro.atgcp1-prod.kartverket.cloud/collect",
  instrumentations: [
    ...getWebInstrumentations(),
    new ReactIntegration({
      router: createReactRouterV6DataOptions({
        matchRoutes,
      }),
    }),
  ],
  sessionTracking: {
    enabled: false,
  },
})

export default faro
