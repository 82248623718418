import { Box, Button, Image, LinkOverlay } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import pin from "../../../assets/pin.svg"
import { useScreenView } from "../../core/hooks/useScreenview"
import {
  Bygningskoordinat,
  Koordinat,
} from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { DetaljertEiendom } from "../../eiendom/detaljert-eiendom/types/DetaljertEiendom"
import Kartutsnitt from "./Kartutsnitt"

interface KartutsnittForBygningProps {
  eiendom: DetaljertEiendom
  bygningskoordinat?: Bygningskoordinat
  representasjonspunkt?: Koordinat
}

const getKartutsnittLenke = (bygningsKoordinat: Koordinat): string =>
  `#!?project=seeiendom&zoom=16&lat=${bygningsKoordinat.lat}&lon=${bygningsKoordinat.long}&markerLat=${bygningsKoordinat.lat}&markerLon=${bygningsKoordinat.long}&panel=Seeiendom&showSelection=true`

const KartutsnittMedBygningskoordinat = forwardRef<HTMLImageElement, KartutsnittForBygningProps>(
  ({ eiendom, bygningskoordinat, representasjonspunkt }: KartutsnittForBygningProps, ref) => {
    const posthog = usePostHog()
    const { isMobile, isDesktop } = useScreenView()
    const { t } = useTranslation("bygningskort")

    const [harKartutsnitt, setHarKartutsnitt] = useState(false)

    return (
      <Box
        pos="relative"
        overflow="hidden"
        {...(!isMobile && {
          borderRadius: "var(--kvib-radii-md)",
          boxShadow: "var(--kvib-shadows-base)",
        })}
        aspectRatio="4/3"
        ref={ref}
      >
        <Kartutsnitt
          queryKeyId="bygning"
          eiendom={eiendom}
          onLoadComplete={({ isLoaded, isMapMissing }) => {
            if (isLoaded && !isMapMissing) {
              setHarKartutsnitt(true)
            }
          }}
          feilmelding={t("feilmelding_ved_manglende_kartutsnitt") as string[]}
          {...(!harKartutsnitt && { transform: "scaleX(-1)", filter: "grayscale(100%)" })} // dersom placeholder vises skal denne speilvendes og gråtoner legges på
        />
        {harKartutsnitt && (
          <>
            {bygningskoordinat?.representasjonspunkt && (
              <Box
                pos="absolute"
                left={`${bygningskoordinat.representasjonspunkt.long}%`}
                top={`${100 - bygningskoordinat.representasjonspunkt.lat}%`}
                transform="translate(-50%, -80%)"
                zIndex="1"
              >
                <Image src={pin} aria-hidden="true" h="36px" />
              </Box>
            )}

            {representasjonspunkt && (
              <LinkOverlay
                href={`https://norgeskart.no/${getKartutsnittLenke(representasjonspunkt)}`}
                isExternal
                tabIndex={-1}
              >
                <Button
                  rightIcon="open_in_new"
                  onClick={() => {
                    posthog.capture("Ekstern lenke", {
                      link_name: "Vis bygning i kart",
                      tilAdresse: "https://norgeskart.no/{bygning}",
                    })
                  }}
                  colorScheme="blue"
                  position="absolute"
                  right={2}
                  bottom={2}
                  iconAriaIsHidden
                  size={isDesktop ? "md" : "sm"}
                >
                  {t("vis_bygning_i_kart_knapp")}
                </Button>
              </LinkOverlay>
            )}
          </>
        )}
      </Box>
    )
  },
)

export default KartutsnittMedBygningskoordinat
