import { Box, Image, ImageProps, Skeleton } from "@kvib/react"
import { forwardRef } from "react"

interface Props extends ImageProps {
  isLoaded: boolean
}

const ImageWithSkeleton = forwardRef<HTMLDivElement, Props>(
  ({ isLoaded, children, ...props }, ref) => (
    <Box
      position="relative"
      h="100%"
      w="100%"
      overflow="hidden"
      objectFit="cover"
      ref={ref}
      borderRadius={props.borderRadius}
    >
      <Skeleton
        h="100%"
        w="100%"
        pos="absolute"
        top={0}
        left={0}
        borderRadius={props.borderRadius}
        isLoaded={isLoaded}
      >
        <Image objectFit="cover" h="100%" w="100%" {...props} />
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          borderRadius={props.borderRadius}
          overflow="hidden"
        >
          {children}
        </Box>
      </Skeleton>
    </Box>
  ),
)

export default ImageWithSkeleton
