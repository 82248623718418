import { Box, Button, Flex, Icon, Popover, PopoverProps, PopoverTrigger } from "@kvib/react"
import { usePostHog } from "posthog-js/react"
import { ReactElement, cloneElement, useEffect, useRef, useState } from "react"
import PopoverInfo from "./PopoverInfo"

interface Props extends PopoverProps {
  posthogName?: string
  forklaring?: string
  buttonAriaLabel: string
  children: [ReactElement, ReactElement]
}

const DetaljPopover = ({ forklaring, ...props }: Props) => {
  // brukes for å gruppere aria-opplesningen
  const randomId1 = Math.random().toString(36).substring(7)
  const randomId2 = Math.random().toString(36).substring(7)
  const posthog = usePostHog()

  const elementRef = useRef<HTMLDivElement>(null)
  const [isHovered, setIsHovered] = useState(false)

  // trengs fordi Icon-komponenten ikke godtar _hover-styling
  useEffect(() => {
    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    const element = elementRef.current
    if (element) {
      element.addEventListener("mouseenter", handleMouseEnter)
      element.addEventListener("mouseleave", handleMouseLeave)
    }

    return () => {
      if (element) {
        element.removeEventListener("mouseenter", handleMouseEnter)
        element.removeEventListener("mouseleave", handleMouseLeave)
      }
    }
  }, [])

  return (
    <Popover>
      <Box
        aria-labelledby={`${randomId1} ${randomId2}`}
        onClick={e => {
          e.stopPropagation()
          if (props.posthogName) posthog.capture(props.posthogName)
        }}
        onKeyDown={e => {
          e.stopPropagation()
          if (props.posthogName) posthog.capture(props.posthogName)
        }}
        w="fit-content"
      >
        <PopoverTrigger>
          <Flex alignItems="center" as={Button} variant="ghost" p="0" h="auto" ref={elementRef}>
            {cloneElement(props.children[0], { id: randomId1, "aria-hidden": "true" })}
            {forklaring && (
              <Box ml="4px">
                <Icon
                  aria-label={props.buttonAriaLabel}
                  icon="info"
                  id={forklaring}
                  color="green.500"
                  isFilled={isHovered}
                />
              </Box>
            )}
          </Flex>
        </PopoverTrigger>
        {cloneElement(props.children[1], { id: randomId2, "aria-hidden": "true" })}
        <PopoverInfo>{forklaring}</PopoverInfo>
      </Box>
    </Popover>
  )
}

export default DetaljPopover
