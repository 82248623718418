import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const TeigMedFlereMatrikkelenheterDetalj = () => {
  const { t } = useTranslation("detaljertInnsyn")

  return (
    <DetaljPopover
      forklaring={t("teig_med_flere_matrikkelenheter_forklaring") as string}
      buttonAriaLabel={t("teig_med_flere_matrikkelenheter_aria_label") as string}
    >
      <OverskriftForEiendomsdetaljer>
        {t("teig_med_flere_matrikkelenheter_overskrift")}
      </OverskriftForEiendomsdetaljer>
      <FeltverdiForEiendomsdetaljer
        fieldValue={t("teig_med_flere_matrikkelenheter_tekst") as string}
      />
    </DetaljPopover>
  )
}

export default TeigMedFlereMatrikkelenheterDetalj
