import {
  DetaljertEiendomResponse,
  EiendomResponse,
  VegadresseResponse,
} from "../../../shared-api/types/ApiResponseTypes"

import { formaterNavn } from "../../../../utils/formateringUtils"
import { Eiendom, Matrikkelenhetstype, Vegadresse } from "../../eiendomsoversikt/types/Eiendom"
import { DetaljertEiendom } from "../types/DetaljertEiendom"

export const mapToDetaljertEiendomFromApiResponse = (
  response: DetaljertEiendomResponse,
): DetaljertEiendom => {
  const vegadresser = response.adresser.vegadresse?.map(vegadr => settSammenVegadresse(vegadr))
  const matrikkeladresser = response.adresser.matrikkeladresse

  const matrikkelnummer = response.matrikkelnummer
  const matrikkelenhetstype =
    response.matrikkelenhetstype === "Seksjon" ? "Eierseksjon" : response.matrikkelenhetstype
  const bygningerPåEiendom = response.bygningerPåEiendom
  const bruksenheterPåEiendom = response.bruksenheterPåEiendom.map(bruksenhet => {
    return { ...bruksenhet, erUnummerert: bruksenhet.bruksenhetstype === "U" }
  })

  return {
    matrikkelenhetId: response.matrikkelenhetId,
    matrikkelnummer: matrikkelnummer,
    matrikkelenhetstype: matrikkelenhetstype as Matrikkelenhetstype,
    kommunenavn: formaterNavn(response.kommunenavn),
    adresser: {
      vegadresser: vegadresser,
      matrikkeladresser: matrikkeladresser,
    },
    eierandel: response.eierandel,
    areal: response.areal,
    bygningerPåEiendom: bygningerPåEiendom,
    bruksenheterPåEiendom: bruksenheterPåEiendom,
    koordinat: response.koordinat,
    antallTeiger: response.antallTeiger,
    teigMedFlereMatrikkelenheter: response.teigMedFlereMatrikkelenheter,
  }
}

export const mapToEiendommerFromApiResponse = (response: EiendomResponse[]): Eiendom[] =>
  response.map((eiendom, index) => {
    const vegadresser = eiendom.adresse.vegadresse?.map(vegadr => settSammenVegadresse(vegadr))
    const matrikkeladresser = eiendom.adresse.matrikkeladresse
    const matrikkelnummer = eiendom.matrikkelnummer
    const matrikkelenhetstype =
      eiendom.matrikkelenhetstype === "Seksjon"
        ? "Eierseksjon"
        : (eiendom.matrikkelenhetstype as Matrikkelenhetstype)

    return {
      matrikkelenhetId: eiendom.matrikkelenhetId,
      eiendomsIndex: index + 1,
      matrikkelnummer: matrikkelnummer,
      matrikkelenhetstype: matrikkelenhetstype,
      kommunenavn: formaterNavn(eiendom.kommunenavn),
      adresser: {
        vegadresser: vegadresser,
        matrikkeladresser: matrikkeladresser,
      },
    }
  })

function settSammenVegadresse(vegadresse: VegadresseResponse): Vegadresse {
  return {
    adresseId: vegadresse.adresseId,
    vegnavn: vegadresse.vegnavn,
    husnummer: vegadresse.husnummer,
    bokstav: vegadresse.bokstav,
    bruksenhetsId: vegadresse.bruksenhetsId,
    bruksenhetsnummer: vegadresse.bruksenhetsnummer,
  }
}
