import i18n from "i18next"

/*
 * Støttefunksjoner for formatering av tekst.
 */

export function formaterStorForbokstav(ord: string): string {
  return ord.charAt(0).toLocaleUpperCase() + ord.substring(1).toLocaleLowerCase()
}

export function formaterMedKommaOgOgKunStoreBokstaver(listeMedTekster: string[]) {
  if (listeMedTekster.length === 0) return ""
  if (listeMedTekster.length === 1) return listeMedTekster[0]
  if (listeMedTekster.length === 2)
    return `${listeMedTekster[0]} ${i18n.t("og", { ns: "nøkkelbegreper" })} ${listeMedTekster[1]}`

  const [førsteElement, ...rest] = listeMedTekster
  const alleUnntattSisteElement = rest.slice(0, -1).join(", ")
  const sisteElement = rest[rest.length - 1]

  return `${førsteElement}, ${alleUnntattSisteElement} ${i18n.t("og", { ns: "nøkkelbegreper" })} ${sisteElement}`
}

export function formaterMedKommaOgOg(listeMedTekster: string[]) {
  if (listeMedTekster.length === 0) return ""
  if (listeMedTekster.length === 1) return listeMedTekster[0]
  if (listeMedTekster.length === 2)
    return `${listeMedTekster[0]} ${i18n.t("og", { ns: "nøkkelbegreper" })} ${listeMedTekster[1].toLowerCase()}`

  const [førsteElement, ...rest] = listeMedTekster
  const alleUnntattSisteElement = rest
    .slice(0, -1)
    .map(text => text.toLowerCase())
    .join(", ")
  const sisteElement = rest[rest.length - 1].toLowerCase()

  return `${førsteElement}, ${alleUnntattSisteElement} ${i18n.t("og", { ns: "nøkkelbegreper" })} ${sisteElement}`
}

/*
 * Støttefunksjoner for formatering av navn og stedsnavn.
 */

export function formaterNavn(navn: string): string {
  return navn
    .split(" ")
    .map(navn => navn.split("-").map(formaterStorForbokstav).join("-"))
    .join(" ")
    .replace(/\bOg\b/g, "og")
}
