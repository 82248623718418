import { usePostHog } from "posthog-js/react"
import { useTranslation } from "react-i18next"
import { EksternLenke } from "../../../core/text/components/EksternLenke"
import { Matrikkelnummer } from "../../eiendomsoversikt/types/Eiendom"
import { formaterMatrikkelnummerMedSkråstrek } from "../../eiendomsoversikt/utils/eiendomUtils"

const SeeiendomButton = ({ matrikkelnummer }: { matrikkelnummer: Matrikkelnummer }) => {
  const posthog = usePostHog()
  const seEiendomUrl = `https://seeiendom.kartverket.no/eiendom/${formaterMatrikkelnummerMedSkråstrek(
    matrikkelnummer,
  )}`
  const { t } = useTranslation("detaljertInnsyn")

  return (
    <EksternLenke
      preventTracking={true}
      tilAdresse={seEiendomUrl}
      onClick={() =>
        posthog.capture("Ekstern lenke", {
          link_name: "Åpne eiendommen i Se eiendom",
          tilAdresse: "https://seeiendom.kartverket.no/eiendom/*/*/*",
        })
      }
    >
      {t("se_eiendom_link")}
    </EksternLenke>
  )
}

export default SeeiendomButton
