import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import i18n from "i18next"
import { RouterProvider } from "react-router-dom"
import { getEnv } from "./environments"
import browserRouter from "./features/routing/browserRouter"
import { SkipNavLink } from "@kvib/react"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
    },
  },
})

export default function App() {
  return (
    <>
      <SkipNavLink
        id="main"
        backgroundColor="var(--kvib-colors-green-500) !important"
        color="white"
        position="absolute"
        left="50% !important"
        top="0 !important"
        transform="translate(-50%, 0)"
        borderRadius="0 0 8px 8px"
        width="240px !important"
        padding="8px 16px !important"
        textAlign="center"
      >
        {i18n.t("hopp_til_hovedinnhold", { ns: "navbar" })}
      </SkipNavLink>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={browserRouter} />
        {getEnv() === "localhost" && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  )
}
