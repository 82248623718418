import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { AvsnittMedTittel } from "../../localization/pages/hjelpOgStøtte"

export const OversiktOverDineEiendommer = ({ nynorsk }: { nynorsk?: boolean }) => {
  const { t } = useTranslation("omTjenesten", nynorsk ? { lng: "nn-NO" } : {})
  return (
    <>
      {(
        t("oversikt_over_dine_eiendommer", { returnObjects: true }) as AvsnittMedTittel
      ).avsnitt.map(beskrivelse => (
        <Brødtekst key={beskrivelse}>
          {beskrivelse}
          <br />
        </Brødtekst>
      ))}
    </>
  )
}

export const FantDuIkkeSvar = ({ nynorsk }: { nynorsk?: boolean }) => {
  const { t } = useTranslation("hjelpOgStøtte", nynorsk ? { lng: "nn-NO" } : {})
  return (
    <>
      <Brødtekst>
        {(t("fant_du_ikke_svar", { returnObjects: true }) as AvsnittMedTittel).avsnitt[0]}
      </Brødtekst>
      <EksternLenke tilAdresse="https://www.kartverket.no/eiendom/eiendomsgrenser/matrikkelen-norgeseiendomsregister">
        {t("mer_om_eiendomsregisteret_link")}
      </EksternLenke>
      <br />
      <br />
      <Brødtekst>
        {(t("fant_du_ikke_svar", { returnObjects: true }) as AvsnittMedTittel).avsnitt[1]}
      </Brødtekst>
      <EksternLenke tilAdresse="https://www.kartverket.no/om-kartverket/kontakt-oss">
        {t("kundesenteret_link")}
      </EksternLenke>
    </>
  )
}
