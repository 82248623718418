const keys = {
  "nb-NO": {
    tab_tittel: "Min eiendom",
    tittel: "Min eiendom",
    beskrivelse:
      "Min eiendom er en tjeneste fra Kartverket hvor du kan finne registrerte opplysninger om dine eiendommer. Informasjonen er hentet fra Eiendomsregisteret som inneholder opplysninger om eiendomsgrenser, areal, bygninger, boliger og adresser.",
    loggInnKnapp: "Logg inn for å se dine eiendommer",
    loggInnKnappMobil: "Logg inn",
  },
  "nn-NO": {
    tab_tittel: "Min eigedom",
    tittel: "Min eigedom",
    beskrivelse:
      "Min eigedom er ein teneste frå Kartverket der du kan finne registrerte opplysningar om eigedommane dine. Informasjonen er henta frå Eiendomsregisteret som inneheld opplysningar om eigedomsgrenser, areal, bygningar, bustader og adresser.",
    loggInnKnapp: "Logg inn for å sjå eigedommane dine",
    loggInnKnappMobil: "Logg inn",
  },
} as const

type LandingssideKeys = keyof (typeof keys)["nb-NO"]

type Landingsside = {
  [K in keyof typeof keys]: Record<LandingssideKeys, string>
}

export const landingsside: Landingsside = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
