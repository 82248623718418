export const freezeScroll = () => {
  const top = window.scrollY
  document.body.style.overflow = "hidden"
  window.onscroll = () => window.scrollTo(0, top)
}

export const unfreezeScroll = () => {
  document.body.style.overflow = ""
  window.onscroll = null
}
