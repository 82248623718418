export const gyldigeBygningsstatuser = [
  "RA",
  "IG",
  "MB",
  "FA",
  "TB",
  "MT",
  "MF",
  "GR",
  "IP",
  "BR",
  "BF",
  "BU",
]
