import { Box, Divider, Skeleton, Stack } from "@kvib/react"
import { t } from "i18next"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"
import KartutsnittForDetaljertEiendom from "../../../kartutsnitt/components/KartutsnittForDetaljertEiendom"
import { formaterAdresseForEiendom } from "../../eiendomsoversikt/utils/adresseForEiendomUtils"
import { DetaljertEiendom } from "../types/DetaljertEiendom"
import { BygningstyperDetalj } from "./BygningstyperDetalj"
import NøkkelinfoOmEiendommen from "./NøkkelinfoOmEiendommen"
import TeigOgArealInfo from "./TeigOgArealInfo"

const Eiendomsdetaljer = ({ eiendom }: { eiendom: DetaljertEiendom | undefined }) => {
  const marginBottomForOverskrift = "8px"

  const adresse =
    eiendom &&
    formaterAdresseForEiendom(
      eiendom.adresser,
      eiendom.matrikkelnummer,
      eiendom.matrikkelenhetstype,
    )

  const KommuneOverskrift = () =>
    eiendom ? (
      <Brødtekst>{t("kommune", { ns: "nøkkelbegreper", navn: eiendom.kommunenavn })}</Brødtekst>
    ) : (
      <Skeleton
        mt="2px"
        h={{
          base: "20px",
          md: "28px",
        }}
        w="120px"
      />
    )

  const AdresseOverskrift = () =>
    adresse ? (
      <Overskrift variant="h1" noOfLines={3}>
        {adresse}
      </Overskrift>
    ) : (
      <Skeleton
        mt="2px"
        h={{
          base: "30px",
          md: "36px",
        }}
        w="240px"
      />
    )

  const SectionDivider = ({ marginBottom = "12px", marginTop = "12px" }) => {
    return <Divider mt={marginTop} mb={marginBottom} />
  }

  return (
    <Stack>
      <Box overflow="hidden" pos="relative">
        <KartutsnittForDetaljertEiendom eiendom={eiendom} />
      </Box>
      <Stack mt="20px">
        <KommuneOverskrift />
        <AdresseOverskrift />
      </Stack>
      <SectionDivider />
      <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
        {t("nøkkelinfo_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <NøkkelinfoOmEiendommen eiendom={eiendom} />
      <SectionDivider />
      <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
        {t("areal_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <TeigOgArealInfo eiendom={eiendom} />
      <SectionDivider />
      <Overskrift color="gray.600" variant="h4" mb={marginBottomForOverskrift}>
        {t("bygning_tittel", { ns: "detaljertInnsyn" })}
      </Overskrift>
      <BygningstyperDetalj bygninger={eiendom?.bygningerPåEiendom} />
    </Stack>
  )
}

export default Eiendomsdetaljer
