export enum Vannforsyningstype {
  OffentligVannverk = "Offentlig vannverk",
  TilknyttetPrivatVannverk = "Tilknyttet privat vannverk",
  AnnenPrivatInnlagtVann = "Annen privat innlagt vann",
  AnnenPrivatIkkeInnlagtVann = "Annen privat ikke innlagt vann",
}

export enum Avlopstype {
  OffentligKloakk = "Offentlig kloakk",
  PrivatKloakk = "Privat kloakk",
  IngenKloakk = "Ingen kloakk",
}

export enum Energikildetype {
  AnnenEnergikilde = "Annen energikilde",
  Biobrensel = "Biobrensel",
  Elektrisitet = "Elektrisitet",
  Fjernvarme = "Fjernvarme",
  Gass = "Gass",
  OljeParafin = "Olje parafin",
  Solenergi = "Solenergi",
  Varmepumpe = "Varmepumpe",
}

export enum Oppvarmingstype {
  Elektrisk = "Elektrisk",
  Sentralvarme = "Sentralvarme",
  AnnenOppvarming = "Annen oppvarming",
}

export const enumToOptions = (enumObj: {
  [key: string]: string | number
}): { [key: string]: string } => {
  return Object.fromEntries(Object.entries(enumObj).map(([key, value]) => [key, value as string]))
}

export const VannforsyningKeysEnum = Object.keys(Vannforsyningstype) as [
  keyof typeof Vannforsyningstype,
]
export const AvlopKeysEnum = Object.keys(Avlopstype) as [keyof typeof Avlopstype]
export const OppvarmingKeysEnum = Object.keys(Oppvarmingstype) as [keyof typeof Oppvarmingstype]
export const EnergikildeKeysEnum = Object.keys(Energikildetype) as [keyof typeof Energikildetype]
