import useEgenregistrering from "../api/useEgenregistrering"
import { Felter } from "../types/schema"

export const useRegistreringstidspunkt = (feltKey?: Felter) => {
  const { data: egenregistreringForBygning } = useEgenregistrering()
  if (!egenregistreringForBygning || !feltKey) return null

  switch (feltKey) {
    case "byggeaar":
      return egenregistreringForBygning.byggeaar?.metadata.registreringstidspunkt
    case "bruksareal":
      return egenregistreringForBygning.bruksareal?.metadata.registreringstidspunkt
    case "energikilder":
      return egenregistreringForBygning.bruksenheter[0]?.energikilder[0]?.metadata
        .registreringstidspunkt
    case "oppvarminger":
      return egenregistreringForBygning.bruksenheter[0]?.oppvarminger[0]?.metadata
        .registreringstidspunkt
    case "vannforsyning":
      return egenregistreringForBygning.vannforsyning?.metadata.registreringstidspunkt
    case "avlop":
      return egenregistreringForBygning.avlop?.metadata.registreringstidspunkt
    default:
      return null
  }
}
