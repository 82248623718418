const keys = {
  "nb-NO": {
    eiendomskort_arialabel:
      "Eiendom {{index, number}}: {{adresse}}. Gå til side for detaljer om eiendommen.",
    mangler_kartutsnitt_for_eiendommen_forklaring:
      "Eiendommen har ingen registrerte teiger som kan vises i kartet.",
    vis_i_kart_knapp: "Åpne i Norgeskart",
    kartutsnitt_eiendom_alt_tittel_med_adresse: "Kartutsnitt av eiendommen {{adresse}}",
    mangler_kartutsnitt_eiendom_alt_tittel_med_adresse:
      "Kartutsnitt er ikke tilgjengelig for eiendommen {{adresse}}",
    eiendom_uten_adresse: "Eiendommen har ingen adresse",
    mine_eiendommer_arialabel_one: "Mine eiendommer. Oversikten viser {{count}} eiendom.",
    mine_eiendommer_arialabel_other: "Mine eiendommer. Oversikten viser {{count}} eiendommer.",
    eiendom_med_antall_adresser_one: "Eiendommen har {{count}} adresse",
    eiendom_med_antall_adresser_other: "Eiendommen har {{count}} adresser",
    les_mer: "Les mer om manglende eiendom",
    mangler_du_en_eiendom: "Mangler du en eiendom?",
    mangler_eiendom_beskrivelse:
      "Noen typer eiendommer, for eksempel borettslag, vil ikke vises i denne oversikten.",
  },
  "nn-NO": {
    eiendomskort_arialabel: "",
    mangler_kartutsnitt_for_eiendommen_overskrift: "",
    mangler_kartutsnitt_for_eiendommen_forklaring: "",
    mangler_kartutsnitt_eiendom_alt_tittel_med_adresse: "",
    vis_i_kart_knapp: "Åpne i Norgeskart",
    kartutsnitt_eiendom_alt_tittel_med_adresse: "",
    eiendom_uten_adresse: "",
    mine_eiendommer_arialabel_one: "",
    mine_eiendommer_arialabel_other: "",
    eiendom_med_antall_adresser_one: "",
    eiendom_med_antall_adresser_other: "",
    les_mer: "Les meir om manglande eigedom",
    mangler_du_en_eiendom: "Manglar du ein eigedom?",
    mangler_eiendom_beskrivelse:
      "Nokre typar eigedomar, til dømes borettslag, vil ikkje visast i denne oversikta.",
  },
}

type EiendomskortKey = keyof (typeof keys)["nb-NO"]

type Eiendomskort = {
  [K in keyof typeof keys]: Record<EiendomskortKey, string>
}

export const eiendomskort: Eiendomskort = {
  "nb-NO": keys["nb-NO"],
  "nn-NO": keys["nn-NO"],
}
