import { Box, Flex } from "@kvib/react"
import { ReactNode } from "react"
import { Overskrift } from "../text/components/Overskrift"

export const FinnerIkkeEiendommerErrorBox = ({
  tittel,
  children,
}: {
  tittel: string
  children: ReactNode
}) => (
  <Box
    bg="#e4f1f8"
    borderRadius="8px"
    as={Flex}
    flexDir="column"
    p={{ base: "20px", lg: "30px" }}
    mt="16px"
    gap={8}
  >
    <Overskrift variant="h2">{tittel}</Overskrift>
    {children}
  </Box>
)
