import { apiRoute } from "../../environments"
import { ApiError } from "../core/error-handling/ApiError"
import { ErrorResponse } from "../shared-api/types/ApiResponseTypes"
import { Bruker } from "./types/Bruker"

export const brukerRouter = {
  hentBruker: async (): Promise<Bruker> => {
    const res = await fetch(`${apiRoute}/bruker/navn`)

    if (!res.ok) {
      const body: ErrorResponse = await res.json()
      throw new ApiError(body)
    }

    const data: Bruker = await res.json()
    return data
  },
}
