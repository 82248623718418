import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
} from "@kvib/react"
import { useFormContext } from "react-hook-form"
import {
  Avlopstype,
  Energikildetype,
  Oppvarmingstype,
  Vannforsyningstype,
  enumToOptions,
} from "../types/Enums"
import { EgenregistreringSchema } from "../types/schema"
import { getModalLabel } from "../utils"
import Dropdown from "./Dropdown"
import Flervalg from "./Flervalg"

interface EgenregistreringModalProps {
  felt: keyof EgenregistreringSchema
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: EgenregistreringSchema) => void
  isLoading: boolean
}

const EgenregistreringModal = ({
  felt,
  isOpen,
  onClose,
  onSubmit,
  isLoading,
}: EgenregistreringModalProps) => {
  const formContext = useFormContext<EgenregistreringSchema>()

  const handleSubmit = () => {
    formContext.handleSubmit(onSubmit)()
  }

  const getModalContent = (field: keyof EgenregistreringSchema) => {
    const placeholderVerdi = `Velg ${field}`

    switch (field) {
      case "byggeaar":
        return (
          <NumberInput>
            <NumberInputField
              {...formContext.register(field, { valueAsNumber: true })}
              name={field}
              placeholder="F.eks. 2023"
            />
          </NumberInput>
        )
      case "bruksareal":
        return (
          <NumberInput>
            <NumberInputField
              {...formContext.register(field, { valueAsNumber: true })}
              name={field}
              placeholder="Oppgis i kvadratmeter"
            />
          </NumberInput>
        )
      case "energikilder":
        return (
          <Flervalg
            name={field}
            label={getModalLabel(field)}
            options={enumToOptions(Energikildetype)}
            register={formContext.register}
          />
        )
      case "oppvarminger":
        return (
          <Flervalg
            name={field}
            label={getModalLabel(field)}
            options={enumToOptions(Oppvarmingstype)}
            register={formContext.register}
          />
        )
      case "vannforsyning":
      case "avlop":
        return (
          <Dropdown
            placeholder={placeholderVerdi}
            label={getModalLabel(field)}
            options={
              field === "vannforsyning"
                ? enumToOptions(Vannforsyningstype)
                : enumToOptions(Avlopstype)
            }
            name={field}
            register={formContext.register}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getModalLabel(felt)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{getModalContent(felt)}</ModalBody>
        {formContext.formState.errors[felt] && (
          <Alert status="error" variant="top-accent">
            <AlertIcon />
            <AlertDescription>{formContext.getFieldState(felt).error?.message}</AlertDescription>
          </Alert>
        )}
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              formContext.clearErrors()
              onClose()
            }}
          >
            Avbryt
          </Button>
          <Button onClick={handleSubmit} isLoading={isLoading}>
            Send inn
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EgenregistreringModal
