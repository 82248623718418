import { FormLabel, NumberInput, NumberInputField, Stack } from "@kvib/react"
import { useFormContext } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

interface InputfeltTallProps {
  name: keyof EgenregistreringSchema
  label: string
  placeholder: string
  felt: string
}

const InputfeltTall = ({ name, label, placeholder }: InputfeltTallProps) => {
  const { register } = useFormContext()

  return (
    <Stack w="fit-content">
      <FormLabel>{label}</FormLabel>
      <NumberInput>
        <NumberInputField
          {...register(name, { valueAsNumber: true })}
          name={name}
          placeholder={placeholder}
        />
      </NumberInput>
    </Stack>
  )
}

export default InputfeltTall
