import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@kvib/react"
import { useTranslation } from "react-i18next"

export const Driftsmelding = () => {
  const { t } = useTranslation("errormeldinger")
  return visDriftsmelding() ? (
    <Alert status="warning">
      <AlertIcon />
      <Box>
        <AlertTitle>{t("nedetid_tittel")}</AlertTitle>
        <AlertDescription>{t("nedetid_beskrivelse")}</AlertDescription>
      </Box>
    </Alert>
  ) : (
    <></>
  )
}

const nedetid = ["2024-08-29", "2024-09-26", "2024-10-31"]

const visDriftsmelding = () => {
  const today = new Date().toISOString().slice(0, 10)
  return nedetid.includes(today)
}
