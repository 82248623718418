import { Box, Button, Flex, Image } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import manglerEiendommerIllustrasjon from "../../../../assets/questionmark_man.svg"
import { useScreenView } from "../../../core/hooks/useScreenview"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { Overskrift } from "../../../core/text/components/Overskrift"

const HvorforSerJegIkkeEiendomCard = ({
  skalVisesSomRad,
  visLogo,
}: {
  skalVisesSomRad: boolean
  visLogo: boolean
}) => {
  const { t } = useTranslation("eiendomskort")
  const { isMobile } = useScreenView()
  const navigate = useNavigate()
  return (
    <Box borderRadius="10px">
      <Image
        src={manglerEiendommerIllustrasjon}
        p="12px"
        margin="auto"
        aria-hidden="true"
        hidden={(!skalVisesSomRad && visLogo) || skalVisesSomRad || isMobile}
      />
      <Flex flexDir={!skalVisesSomRad ? "column" : "row"} m="3">
        <Flex flexDir="column" p="5">
          <Overskrift variant="h2" children={t("mangler_du_en_eiendom")} />
          <Brødtekst color="gray.600" m="2">
            {t("mangler_eiendom_beskrivelse")}
          </Brødtekst>
        </Flex>
        <Button
          m={skalVisesSomRad ? "auto" : "5"}
          p={{ base: 10, md: 6, lg: 8 }}
          variant="secondary"
          onClick={() => navigate("/hjelp#accordion-button-0")}
          whiteSpace="normal"
        >
          {t("les_mer")}
        </Button>
      </Flex>
    </Box>
  )
}

export default HvorforSerJegIkkeEiendomCard
