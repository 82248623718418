import { ListItem, Stack, UnorderedList } from "@kvib/react"
import { useTranslation } from "react-i18next"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { AvsnittMedTittel } from "../../localization/pages/hjelpOgStøtte"
const InfotekstHvorforDukkerIkkeEiendommenMinOpp = ({
  skalViseFnrSomÅrsak,
}: {
  skalViseFnrSomÅrsak?: boolean
}) => {
  const { t } = useTranslation("ofteStilteSpørsmål")
  const avsnitt = (
    t("hvorfor_ser_jeg_ikke_eiendommen", {
      returnObjects: true,
    }) as AvsnittMedTittel
  ).avsnitt

  return (
    <Stack>
      <Brødtekst>{avsnitt[0]}</Brødtekst>
      <UnorderedList spacing={4}>
        {skalViseFnrSomÅrsak && (
          <ListItem>
            <Brødtekst fontWeight="bold"> {avsnitt[1]} </Brødtekst> {avsnitt[2]}
          </ListItem>
        )}
        <ListItem>
          <Brødtekst fontWeight="bold"> {avsnitt[3]} </Brødtekst> {avsnitt[4]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold"> {avsnitt[5]} </Brødtekst> {avsnitt[6]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold">{avsnitt[7]} </Brødtekst> {avsnitt[8]}
        </ListItem>
        <ListItem>
          <Brødtekst fontWeight="bold">{avsnitt[9]} </Brødtekst> {avsnitt[10]}
        </ListItem>
      </UnorderedList>
    </Stack>
  )
}

export default InfotekstHvorforDukkerIkkeEiendommenMinOpp
