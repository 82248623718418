import { Control } from "ol/control"
import { kartpadding } from "../constants"

export class ResetZoomControl extends Control {
  private extent: number[]

  constructor(extent: number[]) {
    const resetZoomKnapp = document.createElement("button")
    resetZoomKnapp.innerHTML = '<span class="material-symbols-rounded">my_location</span>'

    const element = document.createElement("div")
    element.className = "ol-control"
    element.style.bottom = "0.5em"
    element.style.left = "0.5em"
    resetZoomKnapp.style.display = "flex"
    resetZoomKnapp.style.justifyContent = "center"
    resetZoomKnapp.style.alignItems = "center"

    element.appendChild(resetZoomKnapp)

    super({
      element: element,
    })

    this.extent = extent
    resetZoomKnapp.addEventListener("click", this.reset.bind(this), false)
  }

  reset() {
    const map = this.getMap()
    if (map) {
      const view = map.getView()
      if (view) {
        view.fit(this.extent, {
          size: map.getSize(),
          padding: Array(4).fill(kartpadding),
        })
      }
    }
  }
}
