import { format } from "date-fns"
import { Brødtekst } from "../../../core/text/components/Brødtekst"
import { useRegistreringstidspunkt } from "../hooks/useRegistreringstidspunkt"
import { Felter } from "../types/schema"

const Registreringstidspunkt = ({ feltKey }: { feltKey: Felter }) => {
  const registreringstidspunkt = useRegistreringstidspunkt(feltKey)

  if (!registreringstidspunkt) return null

  return (
    <Brødtekst
      litenVariant
      as="i"
    >{`Registrert ${format(registreringstidspunkt, "dd.MM.yyyy")}`}</Brødtekst>
  )
}

export default Registreringstidspunkt
