import { Flex } from "@kvib/react"
import { formaterMedKommaOgOg } from "../../../../../utils/formateringUtils"
import { Brødtekst } from "../../../../core/text/components/Brødtekst"
import DetaljPopover from "../../../components/DetaljPopover"
import OverskriftForEiendomsdetaljer from "../../components/OverskriftForEiendomsdetaljer"
import { Bygning, Bygningsnøkkelinfo } from "../types/Bygning"

export const FelterIBygningskort = ({
  attributt,
  bygning,
}: {
  attributt: Bygningsnøkkelinfo
  bygning: Bygning
}) => {
  const verdier = attributt.evaluate(bygning)
  const formattedVerdier = Array.isArray(verdier) ? formaterMedKommaOgOg(verdier) : verdier

  return (
    <Flex alignItems="flex-start" justifyContent="left">
      <DetaljPopover
        buttonAriaLabel={attributt.arialabel}
        forklaring={attributt.forklaringAvAttributt}
      >
        <OverskriftForEiendomsdetaljer>{attributt.title}</OverskriftForEiendomsdetaljer>
        <Brødtekst fontWeight="bold">{formattedVerdier}</Brødtekst>
      </DetaljPopover>
    </Flex>
  )
}
