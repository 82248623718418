import { useToast } from "@kvib/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ApiError } from "../../../core/error-handling/ApiError"
import { api } from "../../../shared-api/router"
import { EgenregistreringBygning } from "../types/Egenregistrering"
import { EgenregistreringSchema } from "../types/schema"

const useOpprettEgenregistrering = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const opprettEgenregistreringMutation = useMutation<
    string,
    ApiError,
    {
      bygningId: string
      eksisterendeEgenregistrering: EgenregistreringBygning
      oppdatertEgenregistrering: EgenregistreringSchema
      bruksenhetsId: string
    }
  >({
    mutationFn: api.egenregistrering.sendEgenregistrering,
    onSuccess: () => {
      toast({ title: "Opplysningene ble registrert", status: "success", duration: 5000 })
      queryClient.invalidateQueries({ queryKey: ["egenregistrering"] })
    },
    onError: err => {
      toast({ title: err.userErrorMessage, status: "error" })
    },
  })

  return opprettEgenregistreringMutation
}

export default useOpprettEgenregistrering
