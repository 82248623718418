import { apiRoute } from "../../../../environments"
import { ApiError } from "../../../core/error-handling/ApiError"
import { EgenregistreringBygning } from "../types/Egenregistrering"
import { EgenregistreringSchema } from "../types/schema"

export const egenregistreringRouter = {
  kanEgenregistrere: () => {
    const url = `${apiRoute}/kanEgenregistrere`

    return new Promise<boolean | null>((resolve, reject) => {
      fetch(url)
        .then(async response => {
          if (!response.ok) {
            resolve(null)
            return
          }
          const kanEgenregistrere: {
            isEnabled: boolean
          } = await response.json()
          resolve(kanEgenregistrere.isEnabled)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  sendEgenregistrering: async ({
    bygningId,
    eksisterendeEgenregistrering,
    oppdatertEgenregistrering,
    bruksenhetsId,
  }: {
    bygningId: string
    oppdatertEgenregistrering: EgenregistreringSchema
    eksisterendeEgenregistrering: EgenregistreringBygning
    bruksenhetsId: string
  }) => {
    const url = `${apiRoute}/egenregistrering/${bygningId}`

    // Midlertidig fiks så vi kan sende ett og ett felt uten å overskrive eksisterende verdier
    const mergeEksisterendeOgOppdatertEgenregistrering = (
      oppdatertEgenregistrering: EgenregistreringSchema,
      eksisterendeEgenregistrering: EgenregistreringBygning,
    ): EgenregistreringSchema => {
      return {
        byggeaar:
          oppdatertEgenregistrering.byggeaar === null
            ? null
            : oppdatertEgenregistrering.byggeaar || eksisterendeEgenregistrering.byggeaar?.data,
        bruksareal:
          oppdatertEgenregistrering.bruksareal === null
            ? null
            : oppdatertEgenregistrering.bruksareal || eksisterendeEgenregistrering.bruksareal?.data,
        oppvarminger:
          oppdatertEgenregistrering.oppvarminger === null
            ? []
            : oppdatertEgenregistrering.oppvarminger ||
              eksisterendeEgenregistrering.bruksenheter[0].oppvarminger.map(
                oppvarming => oppvarming.data,
              ),
        energikilder:
          oppdatertEgenregistrering.energikilder === null
            ? []
            : oppdatertEgenregistrering.energikilder ||
              eksisterendeEgenregistrering.bruksenheter[0].energikilder.map(
                energikilde => energikilde.data,
              ),
        vannforsyning:
          oppdatertEgenregistrering.vannforsyning === null
            ? null
            : oppdatertEgenregistrering.vannforsyning ||
              eksisterendeEgenregistrering.vannforsyning?.data,
        avlop:
          oppdatertEgenregistrering.avlop === null
            ? null
            : oppdatertEgenregistrering.avlop || eksisterendeEgenregistrering.avlop?.data,
      }
    }

    const oppdatertRegistrering = mergeEksisterendeOgOppdatertEgenregistrering(
      oppdatertEgenregistrering,
      eksisterendeEgenregistrering,
    )
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bruksenhetId: bruksenhetsId, ...oppdatertRegistrering }),
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    return res.text()
  },

  hentEgenregistrering: async (bygningId: string) => {
    const url = `${apiRoute}/egenregistrering/${bygningId}`
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!res.ok) {
      const body = await res.json()
      throw new ApiError(body)
    }

    const egenregistrering: EgenregistreringBygning = await res.json()

    return egenregistrering
  },
}
