import { Matrikkelnummer } from "../types/Eiendom"

export function getMatrikkelenhetstype(matrikkelenhetstype: string): string {
  return matrikkelenhetstype === "AnnenMatrikkelenhet" ? "Annen type" : matrikkelenhetstype
}

export function formaterMatrikkelnummer(matrikkelnummer: Matrikkelnummer): string {
  const matrikkelnummerBase = `${matrikkelnummer.kommunenummer}-${matrikkelnummer.gardsnummer}/${matrikkelnummer.bruksnummer}`
  if (matrikkelnummer.festenummer === 0 && matrikkelnummer.seksjonsnummer === 0) {
    return matrikkelnummerBase
  }

  if (matrikkelnummer.seksjonsnummer === 0) {
    return `${matrikkelnummerBase}/${matrikkelnummer.festenummer}`
  }

  return `${matrikkelnummerBase}/${matrikkelnummer.festenummer}/${matrikkelnummer.seksjonsnummer}`
}

export function formaterMatrikkelnummerMedSkråstrek(matrikkelnummer: Matrikkelnummer): string {
  const formatertMedBindestrek = formaterMatrikkelnummer(matrikkelnummer)
  return formatertMedBindestrek.replace(/-/g, "/")
}

type MappedBygningsIdForIkon = keyof typeof ikonerForBygning

export const bygningstypeIkonMapper = (type: string) => {
  if (!(type in ikonerForBygning)) {
    return "other_houses"
  }

  return ikonerForBygning[type as MappedBygningsIdForIkon]
}

const ikonerForBygning = {
  "11": "house",
  "12": "foundation",
  "13": "holiday_village",
  "14": "apartment",
  "15": "corporate_fare",
  "16": "cottage",
  "17": "house_siding",
  "18": "garage_home",
  "19": "other_houses",
} as const

export const verboseMatrikkelnummer = (matrikkelnummer: string) => {
  const [kommunenummer, gardsnummer, bruksnummer, festenummer, seksjonsnummer] =
    matrikkelnummer.split(/[-/]/)

  const kommunenummerTekst = `Kommunenummer: ${kommunenummer}`
  const gardsnummerTekst = `Gårdsnummer: ${gardsnummer}`
  const bruksnummerTekst = `Bruksnummer: ${bruksnummer}`
  const festenummerTekst = festenummer && festenummer !== "0" ? `Festenummer: ${festenummer}` : null
  const seksjonsnummerTekst =
    seksjonsnummer && seksjonsnummer !== "0" ? `Seksjonsnummer: ${seksjonsnummer}` : null

  const verboseMatrikkelnummer = [
    kommunenummerTekst,
    gardsnummerTekst,
    bruksnummerTekst,
    festenummerTekst,
    seksjonsnummerTekst,
  ]
    .filter(tekst => tekst)
    .join(", ")

  return verboseMatrikkelnummer
}
