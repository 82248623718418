import { Box, Button, Center, Flex, Image } from "@kvib/react"
import { Link as ReactRouterLink } from "react-router-dom"

import { useTranslation } from "react-i18next"
import husMedForstorrelsesGlass from "../../../assets/hus_forstorrelsesglass.svg"
import questionmarkMan from "../../../assets/questionmark_man.svg"
import questionmarkWoman from "../../../assets/questionmark_woman.svg"
import Section from "../../core/components/layout/Section"
import StackMedGap from "../../core/components/layout/StackMedGap"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Brødtekst } from "../../core/text/components/Brødtekst"
import { EksternLenke } from "../../core/text/components/EksternLenke"
import { Overskrift } from "../../core/text/components/Overskrift"
import ForklaringAccordionWrapper from "../../datafelter/components/ForklaringAccordionWrapper"
import { DatafelterAccordion } from "../../localization/pages/datafelter"
import { AvsnittMedTittel } from "../../localization/pages/hjelpOgStøtte"
import { InfoCard } from "../components/InfoCard"
import InfoCardEksternLenke from "../components/InfoCardEksternLenke"

const HjelpOgStøtte = () => {
  const { t } = useTranslation(["hjelpOgStøtte", "ofteStilteSpørsmål"])
  const leterEtterAnnenEiendomOversettelse = t("leter_du_etter_annen_eiendom", {
    ns: "hjelpOgStøtte",
    returnObjects: true,
  }) as AvsnittMedTittel

  const kontaktKundesenteretOversettelse = t("kontakt_kundesenteret", {
    ns: "hjelpOgStøtte",
    returnObjects: true,
  }) as AvsnittMedTittel

  const { isMobile } = useScreenView()
  const bakgrunnsfarge = "green.50"
  const feilOgManglerForklaringer = t("hjelpOgStøtte:feilOgManglerForklaringer", {
    returnObjects: true,
  }) as DatafelterAccordion[]

  return (
    <Section pb={["16px", "160px"]} bg="white">
      <Center>
        <StackMedGap>
          <Overskrift variant="h1">{t("overskrift", { ns: "hjelpOgStøtte" })}</Overskrift>
          <StackMedGap gap="16px">
            <InfoCard
              cardHeader={
                (
                  t("hjelpOgStøtte:om_utvalget", {
                    returnObjects: true,
                  }) as AvsnittMedTittel
                ).tittel
              }
              bg={bakgrunnsfarge}
              p="20px"
            >
              <Flex>
                <Box alignContent="center">
                  {(
                    t("hjelpOgStøtte:om_utvalget", {
                      returnObjects: true,
                    }) as AvsnittMedTittel
                  ).avsnitt.map(avsnitt => (
                    <Brødtekst key={avsnitt} pb={4}>
                      {avsnitt}
                      <br />
                    </Brødtekst>
                  ))}
                  <Box mt="28px">
                    <EksternLenke tilAdresse="https://www.kartverket.no/eiendom/eiendomsgrenser/matrikkelen-norgeseiendomsregister">
                      {t("mer_om_eiendomsregisteret_link")}
                    </EksternLenke>
                  </Box>
                </Box>
                <Image src={husMedForstorrelsesGlass} pl="40px" aria-hidden hidden={isMobile} />
              </Flex>
            </InfoCard>
            <InfoCard
              p="20px"
              cardHeader={
                (
                  t("hjelpOgStøtte:hva_betyr_datafeltene", {
                    returnObjects: true,
                  }) as AvsnittMedTittel
                ).tittel
              }
              bg={bakgrunnsfarge}
            >
              <Flex>
                <Box alignContent="center">
                  {(
                    t("hjelpOgStøtte:hva_betyr_datafeltene", {
                      returnObjects: true,
                    }) as AvsnittMedTittel
                  ).avsnitt.map(avsnitt => (
                    <Brødtekst key={avsnitt}>
                      {avsnitt}
                      <br />
                    </Brødtekst>
                  ))}

                  <Button
                    p={0}
                    mt="40px"
                    rightIcon="chevron_right"
                    aria-label={t("hjelpOgStøtte:les_mer_om_datafeltene") as string}
                    variant="tertiary"
                    as={ReactRouterLink}
                    to="/hjelp/datafelter"
                  >
                    {t("hjelpOgStøtte:les_mer_om_datafeltene") as string}
                  </Button>
                </Box>
                <Image src={questionmarkMan} pl="40px" aria-hidden hidden={isMobile} />
              </Flex>
            </InfoCard>
            <ForklaringAccordionWrapper
              ikon={questionmarkWoman}
              tittel={t("feilOgMangler")}
              translations={feilOgManglerForklaringer}
            />
            <Flex
              gap={2}
              direction={{ base: "column", sm: "column", md: "row", lg: "row" }}
              alignItems="stretch"
            >
              <InfoCardEksternLenke
                oversettelse={kontaktKundesenteretOversettelse}
                lenke="https://www.kartverket.no/om-kartverket/kontakt-oss"
                iconButtonArialabel={t("kontakt_kundesenteret_aria")}
              />

              <InfoCardEksternLenke
                oversettelse={leterEtterAnnenEiendomOversettelse}
                lenke="https://seeiendom.kartverket.no/"
                iconButtonArialabel={t("gaa_til_seeiendom_aria")}
              />
            </Flex>
          </StackMedGap>
        </StackMedGap>
      </Center>
    </Section>
  )
}

export default HjelpOgStøtte
