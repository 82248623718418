import { Flex } from "@kvib/react"
import { DetaljertEiendom } from "../types/DetaljertEiendom"
import AntallTeigerDetalj from "./AntallTeigerDetalj"
import Arealmerknad from "./Arealmerknad"
import EiendomsarealDetalj from "./Eiendomsarealdetalj"
import TeigMedFlereMatrikkelenheterDetalj from "./TeigMedFlereMatrikkelenheterDetalj"

const TeigOgArealInfo = ({ eiendom }: { eiendom: DetaljertEiendom | undefined }) => {
  const harArealmerknader = eiendom?.areal && eiendom.areal.arealmerknader?.length > 0
  const harTeigdetaljer = !!eiendom?.teigMedFlereMatrikkelenheter
  const harFlereTeiger = eiendom && eiendom?.antallTeiger > 1

  return (
    <Flex flexDir="column" gap={4}>
      <EiendomsarealDetalj
        key="eiendomsareal"
        erEierseksjon={eiendom?.matrikkelenhetstype === "Eierseksjon"}
        teigareal={eiendom?.areal?.teigareal ?? -1}
      />
      {harArealmerknader && <Arealmerknad arealmerknader={eiendom.areal?.arealmerknader} />}

      {harFlereTeiger && (
        <AntallTeigerDetalj key="antallTeiger" antallTeiger={eiendom?.antallTeiger} />
      )}
      {harTeigdetaljer && <TeigMedFlereMatrikkelenheterDetalj key="teigMedFlereMatrikkelenheter" />}
    </Flex>
  )
}

export default TeigOgArealInfo
