import { t } from "i18next"
import { Overskrift } from "../../../core/text/components/Overskrift"
import BygningskortMedKartutsnitt from "../bygningsdetaljer/components/BygningskortMedKartutsnitt"
import { DetaljertEiendom } from "../types/DetaljertEiendom"

const Bygningsdetaljer = ({ eiendom }: { eiendom: DetaljertEiendom }) => {
  return (
    <>
      <Overskrift variant="h2">
        {t("bygningsdetaljer_overskrift", { ns: "bygningskort" })}
      </Overskrift>
      <BygningskortMedKartutsnitt eiendom={eiendom} />
    </>
  )
}

export default Bygningsdetaljer
