import { boundingExtent } from "ol/extent"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Style from "ol/style/Style"
import { Polygon, Polygoner } from "./types/InteraktivtKart"

export const hentBboxForPolygoner = (polygoner?: Polygoner): number[] | undefined => {
  if (!polygoner) return
  const flat = polygoner
    .flat()
    .flat()
    .map(k => [k.long, k.lat])
  return boundingExtent(
    flat.map(k => {
      return [k[0], k[1]]
    }),
  )
}

export const polygonTilGeoJSON = (polygon: Polygon) => {
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: polygon.map(ring => ring.map(koordinat => [koordinat.long, koordinat.lat])),
    },
  }
}

export const polygonstil = new Style({
  stroke: new Stroke({
    color: "rgba(89, 82, 210)",
    width: 3,
  }),
  fill: new Fill({
    color: "rgba(89, 82, 210, 0.2)",
  }),
})
