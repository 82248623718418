import { Skeleton } from "@kvib/react"
import { useTranslation } from "react-i18next"
import DetaljPopover from "../../components/DetaljPopover"
import FeltverdiForEiendomsdetaljer from "./FeltverdiForEiendomsdetaljer"
import OverskriftForEiendomsdetaljer from "./OverskriftForEiendomsdetaljer"

const AntallTeigerDetalj = ({ antallTeiger }: { antallTeiger: number | undefined }) => {
  const { t } = useTranslation("detaljertInnsyn")

  return antallTeiger ? (
    <DetaljPopover
      forklaring={t("antall_teiger_forklaring") as string}
      buttonAriaLabel={t("antall_teiger_aria_label") as string}
    >
      <OverskriftForEiendomsdetaljer>{t("antall_teiger_overskrift")}</OverskriftForEiendomsdetaljer>
      <FeltverdiForEiendomsdetaljer
        fieldValue={t("antall_teiger", { count: antallTeiger }) as string}
      />
    </DetaljPopover>
  ) : (
    <Skeleton w="100%" h="24px" />
  )
}

export default AntallTeigerDetalj
