import { Checkbox, CheckboxGroup, FormLabel, Stack } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

interface Props<T> {
  name: keyof EgenregistreringSchema
  label: string
  register: UseFormRegister<EgenregistreringSchema>
  options: T
}

const Flervalg = <T extends object>({ name, label, register, options }: Props<T>) => (
  <Stack w="fit-content">
    <FormLabel htmlFor={name}>{label}:</FormLabel>
    <CheckboxGroup>
      {Object.entries(options).map(([key, label]) => (
        <Checkbox defaultChecked={true} key={key} value={key} {...register(name)}>
          {label}
        </Checkbox>
      ))}
    </CheckboxGroup>
  </Stack>
)

export default Flervalg
