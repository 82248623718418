export const arealmerknaderMapper = {
  " ": "Ikke registrert",
  FG: "Hjelpelinje fiktiv",
  HP: "Har punktfester",
  VA: "Hjelpelinje vannkant",
  VE: "Hjelpelinje vegkant",
  NF: "Noen fiktive hjelpelinjer",
  OM: "Omtvistet",
  PF: "Punktfeste",
} as const

export type Arealmerknadskode = keyof typeof arealmerknaderMapper
