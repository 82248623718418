import i18n from "i18next"
import { apiRouteLogin } from "../../environments"

// Del 1 av autentisering.
//  /login i backend som gir oss en /authorize-url mot ID-porten
export async function getAuthUrlAndRedirect() {
  try {
    const response = await fetch(apiRouteLogin)
    if (!response.ok)
      throw new Error(
        i18n.t("feil_i_autentiseringsrespons", { ns: "errormeldinger", status: response.status }),
      )
    const authUrl = await response.text()
    window.location.assign(authUrl)
  } catch {
    throw new Error(i18n.t("kunne_ikke_autentisere_bruker", { ns: "errormeldinger" }))
  }
}
