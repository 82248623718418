import { mapToEiendommerFromApiResponse } from "../../detaljert-eiendom/api/mapApiResponseToDetaljertEiendom"
import { apiRoute } from "../../../../environments"
import { EiendomResponse, ErrorResponse } from "../../../shared-api/types/ApiResponseTypes"
import { ApiError } from "../../../core/error-handling/ApiError"

export const eiendommerRouter = {
  hentEiendommer: async () => {
    const res = await fetch(`${apiRoute}/eiendommer`)

    if (!res.ok) {
      const body: ErrorResponse = await res.json()
      throw new ApiError(body)
    }

    const data: EiendomResponse[] = await res.json()
    return mapToEiendommerFromApiResponse(data)
  },
}
