import { useQuery } from "@tanstack/react-query"
import { konverterFraMinutterTilMillisekunder } from "../../../utils/konverterFraMinutterTilMillisekunder"
import { Bygning } from "../../eiendom/detaljert-eiendom/bygningsdetaljer/types/Bygning"
import { Matrikkelnummer } from "../../eiendom/eiendomsoversikt/types/Eiendom"
import { api } from "../../shared-api/router"

const useBygningskoordinater = (
  bredde: number | null,
  høyde: number | null,
  matrikkelnummer?: Matrikkelnummer,
  bygninger?: Bygning[],
) => {
  const isValidSize = bredde !== null && høyde !== null
  const queryKey = ["bygningsmarkører", matrikkelnummer, bygninger, bredde, høyde]
  const kartutsnittBygningQuery = useQuery({
    queryKey,
    staleTime: konverterFraMinutterTilMillisekunder(30),
    queryFn: () =>
      api.kartutsnitt.hentKoordinaterForBygningerPåEiendom(
        matrikkelnummer!,
        bygninger!,
        bredde!,
        høyde!,
      ),
    throwOnError: true,
    enabled: !!matrikkelnummer && !!bygninger && isValidSize,
  })

  return kartutsnittBygningQuery
}

export default useBygningskoordinater
