import { Flex, FormLabel, Select } from "@kvib/react"
import { UseFormRegister } from "react-hook-form"
import { EgenregistreringSchema } from "../types/schema"

interface Props<T> {
  name: keyof EgenregistreringSchema
  label: string
  placeholder: string
  register: UseFormRegister<EgenregistreringSchema>
  options: T
}

const Dropdown = <T extends { [key: string]: string }>({
  name,
  label,
  register,
  placeholder,
  options,
}: Props<T>) => {
  return (
    <Flex alignItems="center" gap="1rem" w="100%" justifyContent="space-between">
      <FormLabel htmlFor={name}>{label}:</FormLabel>
      <Select w="60%" placeholder={placeholder} {...register(name)}>
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

export default Dropdown
