import useEgenregistrering from "../api/useEgenregistrering"
import { Felter } from "../types/schema"
import { ikkeRegistrertVerdi, visFeltverdi } from "../utils"

const useErTomVerdi = () => {
  const { data: egenregistreringForBygning } = useEgenregistrering()

  return (felt: Felter) => {
    if (!egenregistreringForBygning) {
      return true
    }

    const fieldValue = visFeltverdi(felt, egenregistreringForBygning)
    return fieldValue === ikkeRegistrertVerdi
  }
}

export default useErTomVerdi
