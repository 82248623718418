import { useQuery } from "@tanstack/react-query"
import { ApiError } from "../../core/error-handling/ApiError"
import { api } from "../../shared-api/router"
import { Bruker } from "../types/Bruker"

export const useBruker = () => {
  const brukerQuery = useQuery<Bruker, ApiError>({
    queryKey: ["bruker"],
    queryFn: api.bruker.hentBruker,
  })

  return brukerQuery
}
