import { Projection } from "ol/proj"

// Kartverkets egen extent for UTM33N-kartene. Fikk denne fra Håvard Vidme
export const KART_EXTENT = [-2500000.0, 3500000.0, 3045984.0, 9045984.0]

export const projeksjon = new Projection({
  code: "EPSG:25833",
  extent: KART_EXTENT,
  units: "m",
})

export const kartpadding = 40 // i piksler fra kantene av kartet
