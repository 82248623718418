import { Box, Flex, Image } from "@kvib/react"
import { useScreenView } from "../../core/hooks/useScreenview"
import { Overskrift } from "../../core/text/components/Overskrift"
import { DatafelterAccordion } from "../../localization/pages/datafelter"
import ForklaringAccordion from "./ForklaringAccordion"

interface ForklaringAccordionWrapperProps {
  tittel: string
  ikon: string
  translations: DatafelterAccordion[] | string
}
const ForklaringAccordionWrapper = (props: ForklaringAccordionWrapperProps) => {
  const { translations, tittel, ikon } = props
  const { isDesktop } = useScreenView()
  return (
    <Flex
      bg="green.50"
      p={{ base: "5px", sm: "20px", md: "20px", lg: "40px" }}
      borderRadius="var(--kvib-radii-lg)"
      boxShadow="var(--kvib-shadows-base)"
    >
      <Box w="100%">
        <Overskrift variant="h3" children={tittel} mb="18px" />
        <ForklaringAccordion translations={translations} />
      </Box>
      <Box pl="58px" pt="50px" hidden={!isDesktop}>
        <Image src={ikon} aria-hidden="true" />
      </Box>
    </Flex>
  )
}

export default ForklaringAccordionWrapper
